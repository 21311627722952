.Nav-bg {
  background-color: var(--ansarada-black);
  display: flex;
  flex-direction: row;
}

.logo {
  padding-top: 25px;
  width: 50px;
  height: 50px;
  opacity: 0.5;
  background-size: 36px 50px;
  background-position: center center;
  background-repeat: no-repeat;
}

.AnsardaSmall {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 10px;
}

.Ansaradalogo {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
}

.Ansaradalogo div {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.Ansaradalogo label {
  font-size: 16px;
  margin-left: 20px;
  color: var(--white);
  display: flex;
  align-items: center;
  height: 30px;
  border-right: 1px solid #57575a;
  width: 70px;
}

.AnsaradalogoN {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
}

/* .AnsaradalogoN img {
  width: 30px;
  height: 30px;
  object-fit: contain;
} */

.AnsaradalogoN label {
  font-size: 13px;
  font-weight: bold;
  color: var(--white);
  margin-bottom: 0 !important;
}

.Page-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 50px;
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: left;
  color: white;
}

.Page-title-mobile {
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: white;
}

.dProgBar {
  position: absolute;
  top: 40px;
  right: 0;
}

.navProPanel {
  height: 70px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.08);
}

@media screen and (min-width: 1166px) {
  .navProPanel {
    width: 420px;
  }
}

@media screen and (max-width: 1165px) {
  .navProPanel {
    width: 350px;
  }
}

@media screen and (max-width: 950px) {
  .navProPanel {
    width: 270px;
  }
}

.mProgBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: var(--dark-grey);
}

.progressTitle{
  height: 32px;
  width: 325px;
  font-size: 12px;
  color: var(--white);
  margin-top: 8px;
  margin-bottom: 3px;
}

.account-info {
  position: absolute;
  padding: 20px 20px 0px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: var(--white);
  color: var(--warm-grey);
  line-height: 3.33;
  z-index: 50;
}

.account-info:after, .account-info:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.account-info:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: var(--white);
	border-width: 5px;
  radius: 2px;
	margin-left: 70px;
}
.account-info:before {
	border-color: rgba(242, 242, 242, 0);
	border-bottom-color: var(--light-grey);
	border-width: 8px;
  radius: 2px;
	margin-left: 67px;
}

.maccount-info {
  position: absolute;
  border-radius: unset;
  background: var(--white);
  color: var(--warm-grey);
  line-height: 3.33;
  z-index: 50;
  width: 100%;
  bottom: 50px;
  left: 0;
  top: 0;
}

.maccountexit {
  position: absolute;
  width: 100%;
  height: 50px;
  left: 0;
  bottom: 0;
}

.maccount-Inner {
  height: 100%;
}

.uploadPanel {

}

.uploadTitle {
  width: 260px;
  display: flex;
  flex-direction: column;
  line-height: 1.33;
}

.uploadTitle label {
  overflow: hidden;
  white-space: nowrap;
}

.uploadTitle span {
  font-size: 12px;
}

@media screen and (max-width: 810px) {
  .Nav-bg {
    /* height: 65px; */
    background-color: var(--dark-grey);
    display: flex;
    flex-direction: row;
  }

  .logo {
    padding-top: 10px;
    width: 60px;
    height: 60px;
    opacity: 0.5;
  }

  .Athenalogo {
    width: 36px;
    height: 50px;
    margin-left: 10px;
    object-fit: contain;
  }

  .Page-title {
    height: 100%;
    line-height: 1;
    font-size: 25px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: white;
  }

  .navProPanel {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: var(--dark-grey);
  }

  .progressTitle{
    height: 100%;
    width: 100%;
    font-size: 12px;
    color: var(--white);
    margin-top: 4px;
    margin-bottom: 2px;
  }

  .uploadPanel {
    overflow-y: auto;
  }

  .uploadTitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 1.33;
  }
}

.bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navtop {
  display: flex;
  justify-content: space-between;
  margin: 10px 50px;
  /*align-items: baseline;*/
}

.barright {
  float: right;
}

.Page-title label {
  font-size: 18px;
  color: var(--white);
}

.navBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 50px;
  background-color: var(--ansarada-green);
  display: flex;
}

.navtop ul {
  list-style-type: none;
  padding-left: 1px;
}

.navtop li {
  display: inline;
}

.board-menu-tabs {
  display: flex;
}

.board-menu-tabs button:focus {
  box-shadow: none !important;
}

.bar .navtop * {
  /* cursor: pointer; */
} 

.boardMenuItem {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
}

.menuItem {
  height: 15px;
  margin-right: 25px;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
}

.menuItem:hover {
  color: var(--white);
}

.menuItem span {
  font-size: 10px;
  font-weight: bold;
  margin-top: 0px;
  position: absolute;
  color: white;
  background: red;
  border: 1px solid red;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  text-align: center;
}

.menuItemLink:hover {
  color: var(--dark-grey);
  cursor: pointer;
}

.menuright {
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.board-company-text {
  font-size: 13px;
  color: white;
  margin: -5px 0 14px 50px;
}

.board-menu-text, .board-menu-text-freemium {
  margin-top: -1px;
}

@media screen and (min-width: 1300px) {
  .board-menu-text, .board-menu-text-freemium {
    max-width: 450px;
  }
}

@media screen and (max-width: 1299px) {
  .board-menu-text, .board-menu-text-freemium {
    max-width: 250px;
  }
}

@media screen and (max-width: 1170px) {
  .board-menu-text, .board-menu-text-freemium {
    max-width: 150px;
  }
}

@media screen and (max-width: 1065px) {
  .board-menu-text, .board-menu-text-freemium {
    max-width: 100px;
  }
}

@media screen and (max-width: 870px) {
  .board-menu-text, .board-menu-text-freemium {
    max-width: 70px;
  }
}

@media screen and (max-width: 809px) {
  .board-menu-text, .board-menu-text-freemium {
    max-width: 25vw;
  }
}

@media screen and (max-width: 500px) {
  .board-menu-text {
    max-width: 20vw;
  }
  .board-menu-text-freemium {
    max-width: 10vw;
  }
}

.menurightpad {
  margin-top: 6px;
  opacity: 0.6;
}

.active {
  opacity: 1.0;
  color: var(--ansarada-green) !important;
  /* text-decoration: underline !important; */
}

.chevronadjust{
  margin-top: -4px;
  margin-left: 4px;
}

.dropTextItem{
  color: var(--warm-grey);
  text-decoration: none;
  cursor: pointer;
}

.dropMenuItem {
  font-size: 14px;
  font-weight: bold;
  border-top: solid 1px var(--medium-grey);
}

.dropMenuItemH {
  font-size: 14px;
  font-weight: bold;
  border-top: solid 1px var(--medium-grey);
  display: flex;
  align-items: center;
  height: 36px;
}

.dropSubMenuItemH {
  font-size: 14px;
  font-weight: bold;
  border-top: solid 1px var(--medium-grey);
  padding: 10px 0px;
}

.sideMenuCompany {
  line-height: 2;
  padding: 20px 0px;
}

.sideMenuCompany div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sideMenuCompany label {
  cursor: pointer;
}

.sideMenuCompany span {
  color: var(--ansarada-green)
}

.dropMenuTitle {
  line-height: normal;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: bold;
}

.dropMenuTitle a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

.navError {
  background-color: rgba(237, 18, 18, 0.2);
}

.navRestart {
  background-color: rgba(253, 255, 0, 0.2);
}

.navErrorText {
  font-size: 12px;
  color: var(--white);
}

.navVerText {
  font-size: 16px;
  color: var(--white);
}

.progressBar2{
  height: 6px;
  border-radius: 3px;
  background-color: #666666;
}

.progressBarSuccess {
  background-color: var(--lightish-blue);
  height:6px;
  border-radius: 3px;
}

.progressBarConvert {
  background-color: var(--purple);
  height:6px;
  border-radius: 3px;
}

.progressMsg{
  font-size: 10px;
  font-style: italic;
  color: #dedede;
  margin-top: 6px;
  height: 32px;
}

.navProgressbar {
  width: 100%;
}

.navProgressbar .navProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.navProgressbar .navProgressbar-trail {
  stroke: #d6d6d6;
}

.navProgressbar .navProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.navProgressbar .navProgressbar-background {
  fill: #d6d6d6;
}

.navProgressbar.navProgressbar-inverted .navProgressbar-background {
  fill: #3e98c7;
}

.navProgressbar.navProgressbar-inverted .navProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.uploadRow {
  border-top: solid 1px var(--medium-grey);
  padding: 10px 0px;
}

.uploadRemain {
  color: #dedede;
}

.uploadRemain span {
  font-size: 30px;
}

.uploadRemain label {
  font-style: italic;
  font-size: 10px;
}

.uploadItem{
  font-size: 12px;
  width: 280px;
  overflow: hidden;
  white-space: nowrap;
}

.refreshIcon{
  margin-top: 5px;
  margin-bottom: -5px;
  margin-right: 5px;
}

.updates {
  color: white;
  font-style: italic;
  font-size: 10px;
  margin-right: 40px;
}

.contact-container {
  margin: 50px 80px;
}

.navddown {
  float: left;
  overflow: hidden;
}

.navddown.isSuspended .menuItem {
  cursor: not-allowed;
  color: gray !important;
}
.navddown.isSuspended .navddown-content,
.navddown.isSuspended .menuexpicon.isSuspended {
  display: none !important;
}

.navddown .dropbtn {
  /*font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;*/
}

.navbar a:hover, .navddown:hover .dropbtn {
  color: var(--white);
}

.navddown-item, .navddown-item-sub {
  position: relative; 
}

.navddown-board-item, .navddown-board-button {
  padding: 7px 10px;
}

.navddown-board-title {
  padding-left: 10px;
  font-size: 14px;
  color: var(--dark-grey);
  white-space: normal;
}

.navddown-board-item:hover {
  background-color: #ddd;
}

.nav-board-modal {
  max-height: 70vh;
  padding: 0 25px;
  overflow-y: scroll;
}

.navdropdown-board {
  position: absolute;
  width: 100%;
  left: 0;
  top: 65px;
  z-index: 51;
  transition: all .5s ease-in-out;
  background: var(--white);
  overflow-y: hidden;
}

.navdropdown-board-scroll {
  max-height: 70vh;
  overflow-y: auto;
}

.navddown-item-content-contact {
  display: none;
  position: absolute;
  min-width: 140px;
  top: -5px;
  left: 149px;
  padding: 5px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: var(--white);
  color: var(--warm-grey);
  z-index: 2;
  line-height: 2.2 !important;
}

.navddown-item-content {
  display: none;
  position: absolute;
  min-width: 140px;
  top: -5px;
  left: 139px;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: var(--white);
  color: var(--warm-grey);
  line-height: 3.33;
  z-index: 2;
}

.navddown-item-content-sub {
  display: none;
  position: absolute;
  min-width: 140px;
  top: -5px;
  left: 139px;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: var(--white);
  color: var(--warm-grey);
  line-height: 3.33;
  z-index: 2;
}

.navddown-board-menu {
  max-height: calc(100vh - 112px);
  padding: 5px 0;
  border-radius: 4px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1); */
  background: var(--white);
  color: var(--warm-grey);
  z-index: 52;
  overflow-y: auto;
  overflow-x: hidden;
}

.board-offset {
  margin-top: 0 !important;
  padding-bottom: 8px;
}

@media screen and (min-width: 810px) {
  .navddown-board-container {
    width: 500px;
    background: var(--white);
  }
}

@media screen and (max-width: 809px) {
  .navddown-board-container {
    /* width: 300px; */
    background: var(--white);
  }
}

.navdown-board-popper {
  min-width: 150px;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: var(--white);
}

.navdown-board-popper-multi {
  max-height: 45vh;
  overflow-y: auto;
}

.navdown-board-popper-multi-board {
  max-height: 50vh;
  overflow-y: auto;
}

.navdown-board-popper-board {
  max-height: calc(100vh - 192px);
  overflow-y: auto;
}

.disabled-item {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: var(--dark-grey);
  opacity: 0.38;
  padding: 0 20px;
  user-select: none;
}

.navddown-content {
  display: none;
  position: absolute;
  min-width: 140px;
  z-index: 51;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: var(--white);
  color: var(--warm-grey);
  line-height: 3.33;
}

.account-navdown {
  right: 50px;
  width: 180px !important;
}

.account-divider {
  margin: 0 20px;
  border-bottom: solid 1px var(--medium-grey);
}

.account-divider-mobile {
  margin: 3px 15px;
  border-bottom: solid 1px var(--medium-grey);
}

.navddown-content a, .navddown-item-content a, .navddown-item-content-sub a {
  float: none;
  text-decoration: none;
  display: block;
  font-size: 12px;
  font-weight: bold;
  /* border-bottom: solid 1px var(--medium-grey); */
  color: var(--dark-grey);
  padding: 0 20px;
  user-select: none;
}

.navddown-content a:last-child, .navddown-item-content a:last-child, .navddown-item-content-sub a:last-child {
  border-bottom: none;
}

.navddown-content a:hover, .navddown-item-content a:hover, .navddown-item-content-sub a:hover {
  background-color: #ddd;
}

.navddown-item-content-contact a:hover {
  background-color: var(--white) !important;
}

.navddown-item:hover .navddown-item-content {
  display: block;
}

.navddown-item:hover .navddown-item-content-contact {
  display: block;
}

.navddown-item-sub:hover .navddown-item-content-sub {
  display: block;
}

.navddown-item-content:hover .navddown-content {
  display: block;
}

.navddown-item-content-sub:hover .navddown-content {
  display: block;
}

.navddown-item-content-sub:hover .navddown-item-content {
  display: block;
}

.navddown:hover .navddown-content {
  display: block;
}

.menuexpicon{
  margin-top: -3px;
  width: 16px;
}

.menuexpicon-board{
  width: 9px;
}

.slide-pane {
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 100px;
  height: 100%;
  box-shadow: 0 8px 8px rgba(0,0,0,0.5);
  transition: transform 0.25s;
  will-change: transform;
  color: var(--ansarada-dark);
  padding: 20px;
}
.slide-pane:focus {
  outline-style: none;
}
.slide-pane_from_right {
  margin-left: auto;
  transform: translateX(100%);
}
.slide-pane_from_right.ReactModal__Content--after-open {
  transform: translateX(0%);
}
.slide-pane_from_right.ReactModal__Content--before-close {
  transform: translateX(100%);
}
.slide-pane_from_left {
  margin-right: auto;
  transform: translateX(-100%);
}
.slide-pane_from_left.ReactModal__Content--after-open {
  transform: translateX(0%);
}
.slide-pane_from_left.ReactModal__Content--before-close {
  transform: translateX(-100%);
}
.slide-pane_from_bottom {
  height: 90vh;
  margin-top: 10vh;
  transform: translateY(100%);
}
.slide-pane_from_bottom.ReactModal__Content--after-open {
  transform: translateY(0%);
}
.slide-pane_from_bottom.ReactModal__Content--before-close {
  transform: translateY(100%);
}
.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0,0);
  z-index: 80;
}
.slide-pane__overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0,0,0,0.3);
  transition: background-color 0.25s;
}
.slide-pane__overlay.ReactModal__Overlay--before-close {
  background-color: rgba(0,0,0,0);
}
.slide-pane__header {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  height: 45px;
  justify-content: space-between;
  flex-direction: row;
}
.slide-pane__title-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 32px;
  min-width: 0;
}
.slide-pane .slide-pane__title {
  font-size: 18px;
  font-weight: normal;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}
.slide-pane__close {
  margin-left: 24px;
  padding: 16px;
  opacity: 0.7;
  cursor: pointer;
}
.slide-pane__close svg {
  width: 12px;
  padding: 0;
}
.slide-pane__content {
  position: relative;
  overflow-y: auto;
  flex: 1 1 auto;
  padding-bottom: 40px;
}
.slide-pane__subtitle {
  font-size: 12px;
  margin-top: 2px;
}

.actoffset {
  margin: 5px 2px 0 0;
}

.navlogo {
  width: 80px;
  margin-right: 10px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-bottom: 8px solid white;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-top: 8px solid white;
}

.navdropdown {
  position: absolute;
  width: 100%;
  left: 0;
  top: 65px;
  z-index: 50;
  overflow-y: hidden;
  transition: all .5s ease-in-out;
  height: 0;
}

.navdropp {
  z-index: 50;
  background-color: white;
  border-bottom: 1px solid var(--light-grey);
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: bold;
}

.navactive {
  height: 100%!important;
}

.navdropdown:target {
  height: 200px;
}

.navCentreLabel {

}

.navBottomLabel {
  display: none;
}

@media screen and (max-width: 500px) {
  .navCentreLabel{
    display: none;
  }

  .navBottomLabel {
    display: block;
    padding: 10px;
  }
}

.msubContent, .msub2Content, .msub3Content {
  overflow-y: hidden;
  transition: all .5s ease-in-out;
  height: 0;
  display: flex;
  flex-direction: column;
}

.msubContent a {
  text-decoration: none;
  color: var(--dark-grey) !important;
  min-height: 30px;
  font-weight: normal;
  align-items: center;
  display: flex;
  margin-left: 20px;
}

.msub2Content a {
  text-decoration: none;
  color: var(--dark-grey) !important;
  min-height: 30px;
  font-weight: normal;
  align-items: center;
  display: flex;
  margin-left: 40px;
}

.msub3Content a {
  text-decoration: none;
  color: var(--dark-grey) !important;
  min-height: 30px;
  font-weight: normal;
  align-items: center;
  display: flex;
  margin-left: 60px;
}

.disabled-item-dropdown {
  color: var(--dark-grey);
  opacity: 0.38;
  min-height: 30px;
  font-weight: normal;
  align-items: center;
  display: flex;
  margin-left: 20px;
}

.mactive {
  height: auto;
}

.mdropdown {
  overflow: hidden;
  display: flex;
  align-items: center;
  /* background-color: var(--light-grey); */
  padding: 0 15px;
  /*background-color: var(--medium-grey);
  min-height: 30px;
  display: flex;
  align-items: center;*/
}

.mdropdown label {
  align-items: center;
  display: flex;
}

.mdropUpdate {
  padding: 0 20px;
  background-color: var(--very-light-grey);
  height: 40px;
  display: flex;
  align-items: center;
}

.moffset {
  margin: 0;
  padding: 0;
}

.mIcon {
  width: 20px;
  max-width: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
}

.mMenuItem {
  text-align: left;
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  padding: 0;
  /*padding: 0 20px;*/
  margin: 0;
  height: 30px;
  display: flex;
  align-items: center;
}

.mMenuItem span {
  font-size: 10px;
  font-weight: bold;
  margin-top: 0px;
  position: absolute;
  color: white;
  background: red;
  border: 1px solid red;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  text-align: center;
}

.navcall {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-menu-item-hover-details {
  position: absolute;
  top: 10px;
  left: 0;
  color: white;
  width: 300px;
  padding: 6px 10px;
  border-radius: 5px;
  background: var(--ansarada-green);
  font-size: 15px;
  line-height: initial;
}

.navbar-menu-item-hover-details-error {
  background: red;
}

.navbar-menu-item-hover-details-right {
  right: 0;
  left: auto;
}

.freemiumTitle label {
  color: white; 
  font-size: 13px;
  margin-right: 20px;
}

/* @media screen and (max-width: 900px) {
  .freemiumTitle label {
    display: none;
  }
} */

.navdown-board-item-disabled {
  background: #efefef;
  cursor: not-allowed !important;
  color: grey;
}

.navdown-board-item-disabled svg {
  color: grey;
}