.topbar {
    background-color: var(--ansarada-dark);
    width: 100%;
    height: 45px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 55px;
}

@media screen and (max-width: 580px) {
    .topbar {
        padding: 0 10px !important;
        height: 80px !important;
    }
}

.topbar-content {
    flex: 1
}

.freemium-stepper svg.MuiStepIcon-root {
    color: var(--warm-grey);
}

.freemium-stepper svg.MuiStepIcon-root.Mui-completed,
.freemium-stepper svg.MuiStepIcon-root.Mui-active {
    background-color: white;
    border-radius: 100%;
    color: var(--ansarada-green);
}

.freemium-stepper .MuiStepLabel-label {
    color: var(--warm-grey);
}

.freemium-stepper .MuiStepLabel-label.Mui-completed,
.freemium-stepper .MuiStepLabel-label.Mui-active {
    color: var(--white);
}

.freemium-container {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    flex: 1;
    flex-direction: row;
    /* background-color: rgb(244, 248, 248); */
}

.freemium-container-inner {
    flex: 1;
    width: 100%;
}

.freemiumSignupContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;
}

.freemium-section {
    /* background-color: white; */
}

.freemium-signup-email-text {
    white-space: normal;
    overflow: hidden;
    overflow-wrap: break-word;
}

.freemium-invite-sent-success {
    margin-top: 20px;
    padding: 10px 0;
    color: var(--ansarada-light-green);
}

.freemium-invite-sent-error {
    color: red;
    font-size: 14px;
    padding: 10px 0;
}

.freemium-right-section {
    width: 50%;
    background-color: rgb(244, 248, 248);
}
.freemium-right-section-svg-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    flex: 1;
    /* animation: fadeIn 1s; */
}

/* @keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
} */

.freemium-right-section-svg-outer>svg {
    width: 100%;
    max-width: 559px;
    height: 568px;
}

@media screen and (max-width: 930px) {
    .freemium-right-section {
        display: none !important;
    }
}


.Asarada-logo {
    object-fit: contain;
    margin-bottom: 32px;
    height: 25px;
}

.top45 {
    margin-top: 45px;
}

.top35 {
    margin-top: 35px;
}

.top25 {
    margin-top: 25px;
}

.signUpAlready {
    color: var(--ansarada-dark);
    padding-top: 50px;
    font-size: 14px;
}

.signUpAlready a {
    text-decoration: none;
    text-decoration-line: underline !important;
    color: var(--ansarada-green);
}

@media screen and (max-width: 760px) {
    .hide-title {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .freemium-section {
        padding: 30px 34px;
    }

    .appRow {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
    }
}

@media screen and (min-width: 551px) {
    .input-container {
        width: 400px;
    }

    .freemium-section {
        padding: 60px 64px;
    }

    .appRow {
        display: flex;
        flex-direction: row;
        margin-top: 25px;
    }
}

.login-error-msgapp-freemium span {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.login-error-msgapp-freemium {
  text-align: center;
  color: #DB353D !important;
  font-size: 13px !important;
  line-height: 16px !important;
  border: 1px solid;
  border-radius: 4px;
  margin: 10px 0 0;
  text-transform: none !important;
  font-weight: bold !important;
  padding: 8px 24px !important;
  /* width: 100%; */
  background-color: white;
}

.app-video-freemium-flow {
    width: 80%;
    height: 450px;
    margin-top: 30px;
}

@media screen and (max-width: 900px) {
    .app-video-freemium-flow {
        width: 100%;
    }
}

.app-try-it-yourself {
    margin-top: 40px;
}

.app-video {
    width: 100%;
    height: 450px;
    margin-top: 50px;
}

.freemium-whats-next-h2 {
    color: var(--ansarada-green);
    font-weight: 700;
    font-size: 20px;
}

.freemium-contact-us {
    color: var(--ansarada-light-green);
}
.freemium-contact-us:hover, 
.freemium-contact-us:active, 
.freemium-contact-us:visited {
    color: var(--ansarada-green);
}

.iosLogo {
    width: 120px;
    height: 40px;
}

.macLogo {
    width: 156px;
    height: 40px;
}

.winLogo {
    width: 110px;
    height: 39px;
}

.appPanel {
    display: flex;
    flex-direction: column;
    margin: 0 25px 15px 0;
    text-decoration: none;
    color: #07070c;
    font-weight: bold;
}

.freemium-check a {
    color: var(--ansarada-green);
}

.chip-need {
    margin: 0 10px 15px 0 !important;
}

.chip-need-selected {
    margin: 0 10px 15px 0 !important;
    background-color: var(--ansarada-green) !important;
    border-color: var(--ansarada-green) !important;
    color: white !important;
}


.passwordResetWrapper {
    cursor: pointer;
    font-weight: bold;
    color: var(--ansarada-green);
    padding: 10px 0;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-items: center;
}


.passwordResetWrapper-message {
    padding: 5px;
    border: 1px solid var(--ansarada-green);
    border-radius: 5px;
    color: var(--ansarada-green);
    font-size: 14px;
    text-align: center;
    font-weight: bold;
}

.passwordResetWrapper-success-message {
    color: var(--ansarada-green);
}

.passwordResetWrapper-error-message {
    color: red !important;
    border-color: red !important;
}