body {
  margin: 0px !important;
}

.logContentApp{
  background-color: var(--ansarada-black);
  color: var(--white);
  height: 100vh;
  font-size: 16px;
}

.logboxContent{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 95%;
  width: 100%;
}

.loginVersionApp {
  height: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  line-height: 1.43;
  color: white;
}

.loginVersionApp label {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.loginboxApp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  max-width: 600px;
  border-radius: 4px;
  border: 0px solid #ebebed;
  padding: 24px;
  background-color: var(--ansarada-black);
}

@media screen and (min-width: 810px) {
  .loginboxApp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
    max-width: 600px;
    min-width: 500px;
    border-radius: 4px;
    border: 0px solid #ebebed;
    padding: 24px;
    background-color: var(--ansarada-black);
  }
}

.loginboxApp h1 {
  color: var(--white);
}

.login-lock-header {
  height: auto !important;
  font-size: 18px;
  position: relative;
  padding: 11px;
}

.app-logo {
  object-fit: fill;
  margin-bottom: 30px;
  width: 230px;
  height: 120px;
}

.forgotten {
  font-size: 14px;
  font-weight: normal;
  margin-top: 70px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.support {
  font-size: 14px;
  font-weight: normal;
  margin-top: 20px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.login-field {
  width: 100%;
}

.confirm-password {
  margin-top: 20px !important;
}

.login-field label {
  float: left;
  margin-bottom: 8px !important;
  display: block !important;
}

.f-controlApp {
  font-size: 16px;
  height: 46px;
  font-weight: normal;
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--ansarada-grey);
  width: 100% !important;
  padding: 0 0px!important;
}

.f-controlApp:focus {
  outline:0;
  border-color: var(--ansarada-green);
}

.f-controlempty {
  color: var(--ansarada-grey);
}

.f-controlReg {
  font-size: 16px;
  height: 46px;
  font-weight: normal;
  color: var(--ansarada-black);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--ansarada-grey);
  width: 100% !important;
  padding: 0 0px!important;
}

.f-controlReg:focus {
  outline:0;
  border-color: var(--ansarada-green);
}

.f-controlRefempty {
  color: var(--ansarada-grey) !important;
}

.spin-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(52, 61, 72, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
  -moz-animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
  -o-animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
  animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
}

.spin-text {
  color: #57575a;
  font-size: large;
  padding-left: 10px;
}

.bpanel-Exit {
  margin-top: -10px;
  color: var(--warm-grey);
}

.reset-password-close {
  color: var(--ansarada-green) !important;
}

.auth-panel{
  margin: 25px 20px 20px 50px;
}

.authmargApp {
  margin: 0 50px 20px 50px;
  text-align: left;
}

.authmargApp label {
  font-size: 16px;
  font-weight: normal;
  color: white !important;
  text-align: center;
  margin-bottom: 10px;
}

.authmargApp span {
  font-size: 18px;
  font-weight: normal;
  color: var(--warm-grey);
}

.authmargApp boldspan {
  font-size: 18px;
  font-weight: bold;
  color: var(--warm-grey);
}

.screenmarg {
  margin: 0 50px 20px 50px;
  text-align: left;
}

.screenmarg label {
  font-size: 18px;
  font-weight: normal;
  color: var(--warm-grey);
  margin-bottom: 10px;
}

.screenmargnm {
  margin: 0 50px 0px 50px;
  text-align: left;
}

.authcode{
  margin: 20px 0px 40px 0px;
}

.authcodeSmall{
  margin: 20px 0px 20px 0px;
}

.authcode input[type='text'], .authcode input[type='password'] {
  border: 1px solid var(--medium-grey);
  border-radius: 4px;
  height: 40px;
  width: 100% !important;
  color: var(--dark-grey);
  font-size: 24px;
  font-weight: bold;
  margin-left: -5px;
  padding-left: 5px;
}

input[type=text]:focus .ainput{
  outline: none;
  box-shadow: 0 0 0 2px var(--ansarada-green);
}

.authcode input::-webkit-outer-spin-button,
.authcode input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.forgot-msg{
  text-align: left;
  margin: 0 50px 20px 50px;
}

.qrCamera {
  color: var(--ansarada-green);
}

.login-reg {
  position: absolute;
  width: 400px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: var(--light-grey);
  color: var(--warm-grey);
  line-height: 1;
  z-index: 99;
}

.centerAbs {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.login-reg:after, .login-reg:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.login-reg:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: var(--light-grey);
	border-width: 5px;
  radius: 2px;
	margin-left: 0px;
}
.login-reg:before {
	border-color: rgba(242, 242, 242, 0);
	border-top-color: var(--light-grey);
	border-width: 8px;
  radius: 2px;
	margin-left: -3px;
}

.refreshDis{
  margin-top: 3px;
  margin-bottom: -3px;
  margin-right: 5px;
}

.hintp{
  margin-top: 5px;
  margin-bottom: 0px;
}

.qrPopUp {
  position: fixed;
  margin-left: 250px;
  margin-top: -50px;
}

.qrPopUpExit {
  margin-bottom: -20px;
  margin-left: 210px;
}

.confirmInvMin {
  max-height: 300px;
  overflow-y: auto;
}

.confirmInvRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--medium-grey);
}

.resendIcon {
  display: flex;
  margin-top: 5px;
}

.resendIcon span {
  color: var(--ansarada-green);
  margin-left: 5px;
  cursor: pointer;
}

.codesent {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 10px;
}

.fgtext {
  display: flex;
  color: var(--warm-grey);
  font-size: 14px;
}

.fgBox {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
}

.fgpanel {
  padding-top: 28px;
  padding-bottom: 28px;
  background-color: var(--very-light-grey);
}

.fgBox span {
  font-size: 18px;
  color: var(--lightish-blue);
  margin-bottom: 5px;
}

.fgBox label {
  font-size: 14px;
}

.fgBox div {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin-left: 11px;
  margin-right: 11px;
}

.fgadmins {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.fgworddash {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fgworddash label {
  font-size: 18px;
}

.fgworddash span {
  font-size: 32px;
  color: var(--dark-grey);
  margin-top: 20px;
  margin-bottom: 20px;
}

.fgworddash div {
  display: flex;
    justify-content: center;
}

.fgpwd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 50px 30px 50px;
}

.fgpwd label {
  font-size: 14px;
  color: var(--warm-grey);
  margin-bottom: 5px;
}

.fgpwd input[type='text'], .fgpwd input[type='password'] {
  border: 1px solid var(--medium-grey);
  border-radius: 4px;
  height: 40px;
  width: 100%;
  color: var(--dark-grey);
  font-size: 18px;
  /*font-weight: bold;*/
  margin-left: -5px;
  padding-left: 5px;
  background-color: white;
}

.fgpwd input::-webkit-outer-spin-button,
.fgpwd input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.fgrec label {
  font-weight: bold;
}

.fgpassempty {
  color: var(--warm-grey)!important;
  /*font-style: italic;*/
  font-weight: normal !important;
}

.fgicon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.fgcam {
  width: 500px;
  height: 255px;
  border-radius: 4px;
  border: solid 1px var(--medium-grey);
      margin: auto;
}

.loginUserNameApp {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  padding: 5px;
}

.lpassword {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.lpassword svg {
  margin-left: -50px;
  margin-right: 10px;
  height: 40px;
  cursor: pointer;
}

.Auth0OverLay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
}

.Auth0Centre {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.auth0Frame {
  width: 600px;
  height: 800px;
  border: none;
}

.login-iframe-exit {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -450px;
  margin-left: 430px;
  animation: 5s fadeIn;
  animation-fill-mode: forwards;

  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.login-message-msgapp {
  text-align: center;
  color: var(--ansarada-green) !important;
  font-size: 13px !important;
  line-height: 16px !important;
  border: 1px solid;
  border-radius: 4px;
  margin: 8px 0 16px;
  text-transform: none !important;
  font-weight: bold !important;
  padding: 8px 24px !important;
  width: 100%;
  background-color: white;
}

.login-message-msgapp .login-message-link {
  text-decoration: underline;
  cursor: pointer;
  font-size: bold;
}

.login-error-msgapp span {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.login-error-msgapp {
  text-align: center;
  color: #DB353D !important;
  font-size: 13px !important;
  line-height: 16px !important;
  border: 1px solid;
  border-radius: 4px;
  margin: 8px 0 16px;
  text-transform: none !important;
  font-weight: bold !important;
  padding: 8px 24px !important;
  width: 95%;
  background-color: white;
}

.login-error-msgapp span {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.login-forgotApp {
  text-decoration: none !important;
  font-size: 13px;
  color: #FFFFFF !important;
  font-weight: normal;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
}

.login-forgotApp label {
  cursor: pointer;
  margin: auto;
}

.login-forgotApp label span {
  font-weight: bold;
}

#privacy-noteApp {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 13px;
  line-height: 16px !important;
  letter-spacing: 0.34px;
  color: #FFFFFF !important;
  text-align: center;
  margin-bottom: 20px;
}

#privacy-noteApp a {
  text-decoration: none !important;
  color: var(--ansarada-green) !important;
  font-weight: bold;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

.Login-loader {
  background-color: #f7f7f7;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
}

.ansarada-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.authcode-bg {
  background-color: var(--ansarada-black);
}

.authcode-info-bg {
  background-color: var(--ansarada-black);
  /* border-style: solid;
  border-width: 2px;
  border-color: var(--ansarada-green); */
}

.authcode-resend-btn {
  background-color: var(--ansarada-green) !important;
  color: white !important;
  border-style: none !important;
}

.authcode-input {
  color: white;
}

.authcodelog-input {
  color: white !important;
  background-color: transparent;
  border: none !important;
  border-bottom: 1px solid var(--ansarada-grey) !important;
  border-radius: 0px !important;
  width: 200px !important;
  font-size: 16px !important;
}

.authcodelog-empty:focus {
  outline:0;
  border-color: var(--ansarada-green) !important;
}

.authcodelog-empty {
  color: var(--ansarada-grey) !important;
}

.login-button {
  margin-bottom: 20px !important;
  width: 280px !important;
}

.login-button-parent {
  align-items: center;
}

.changepassword {
  border: none;
  border-bottom: 1px solid var(--ansarada-grey);
  border-radius: 0px;
}

.changepassword:focus {
  border-color: var(--ansarada-green);
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.changepasswordempty {
  color: var(--ansarada-grey);
}

.applockscreen {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  align-items: flex-start;
  padding-top: 100px;
}

.applock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--ansarada-black);
  font-size: 18px;
  font-weight: bold;
}

.applock img {
  width: 200px;
  height: 100px;
}

.applock div {
  margin-bottom: 100px;
}

.applock label {
  margin-bottom: 20px;
}

.loginpvre {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: 20px;
}

.loginpassmatch {
  color: yellow;
  font-size: 12px;
  height: 30px;
  display: flex;
  justify-content: center;
}

.authtext {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authrefresh {
  width: 30px;
  height: 30px;
}

.forgot-password-border {
  border: 1px solid var(--ansarada-green);
}

.forgot-password-bg {
  background-color: var(--ansarada-black);
}

.invitecode {
  min-width: 200px;
}

.forgotPass {

}

.forgotPanel {

}
/*
@media screen and (min-width: 810px) {
  .forgotPass {
    margin-left: 50vw;
  }

  .forgotPanel {
    justify-content: flex-start;
  }
}

.forgotPass {
	position: relative;
	background: #fff;
	border: 1px solid #fff;
}
.forgotPass:after, .arrow_box:before {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.forgotPass:after {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #fff;
	border-width: 10px;
	margin-top: -10px;
}
.forgotPass:before {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #fff;
	border-width: 11px;
	margin-top: -11px;
}*/

.regPass {
  background: white;
  border-radius: 20px;
  padding: 20px;
  color: var(--ansarada-black);
}

.regPass > label {
  font-weight: 700;
}

.regBut {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.regGap {
  height: 300px;
}

.regButL {
  min-width: 200px;
}

.forgotPass .authcode-info-bg {
  background-color: white;
  color: var(--ansarada-black);
}

.forgotPass .authmargApp label {
  color: var(--ansarada-black) !important;
}

.forgotPass .authcodelog-input {
  color: var(--ansarada-black) !important;
}

.forgotPass .btn-white {
  color: red;
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
}

.forgotPass .ace-d5w0nm-BaseButton {
  background-color: var(--medium-grey);
}

.regPass .btn-disable {
  opacity: 1;
  cursor: initial !important;
  background-color: var(--ansarada-green);
}

.freemium-password-input {
  border: solid 1px #a7a7aa;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 0%) inset;
  border-radius: 4px;
}

.passError {
  min-height: 21px;
  text-align: center;
  margin-top: 10px;
}

.passPol {
  margin-top: 10px;
}

.polText > span {
  margin-left: 20px;
  margin-right: 25px;
}

.polRow {
  margin-top: 20px;
}

.polRow label, .polGroup label {
  color: black;
}

.polText {
  margin-top: 15px;
  line-height: 1;
}