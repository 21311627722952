:root {
  --tangerine: #fa9a0d;
  --dark-mint: #4ece63;
  --warm-pink: #fa607d;
  --red: #f12314;
  --purple: #8d3add;
  --royal-blue: #08379d;
  --lightish-blue: #4680ff;
  --dark-grey: #333333;
  --warm-grey: #999999;
  --soft-grey: #8e8e93;
  --medium-grey: #d4d4d4;
  --light-grey: #f2f2f2;
  --very-light-grey: #f9f9f9;
  --white: #ffffff;
  --black: #000;
  --update:#9d2408f0;
  --loader-grey: #a3a3a3;
  --citrus: #FEB26C;
  --kiwi: #80EDC0;
  --quandong: #AAF2EA;
  --ansarada-light-green: #06AC47;
  --ansarada-green: #1e8c1a;
  --ansarada-black: #07070c;
  --ansarada-grey: #d4d4d7;
  --ansarada-dark: #2c2c2e;
  --ansarada-light: #57575a;
}

@font-face {
  font-family: 'AktivGrotesk';
  src:  url('/lib/fonts/AktivGrotesk_Rg.ttf') format('truetype');
}

@font-face {
  font-family: 'AnsaradaFavorit';
  src: url('/lib/fonts/AnsaradaFavorit-Medium') format('opentype');
}
@font-face {
  font-family: 'AnsaradaFavorit';
  font-weight: bold;
  src: url("/lib/fonts/AnsaradaFavorit-Bold.otf") format("opentype");
}

body {
  background-color: var(--very-light-grey) !important;
  font-family: 'AnsaradaFavorit', AktivGrotesk, sans-serif, arial !important;
  font-size: 18px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  color: var(--dark-grey) !important;
  text-rendering: optimizeLegibility !important;
}

.content{
  height: 100vh;
  min-height: 100vh;
}

.page {
  display: flex;
  flex-direction: column;
}

.header{
  /* min-height: 80px; */
  background-color: white;
}

.noUnder {
  text-decoration: none;
}

.inlineBlock {
  display: inline-block;
}

.noMargin {
  margin: 0;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: .5rem;
  margin-top: .5rem;
}

h2 {
  font-size: 24px;
  font-weight: normal;
  margin-bottom: .5rem;
  margin-top: .5rem;
}

h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: .5rem;
  margin-top: .5rem;
}

h4 {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: .5rem;
  margin-top: .5rem;
}

/* Text */
.size32-bold {
  font-size: 36px;
  font-weight: bold;
}

.size32-bold {
  font-size: 32px;
  font-weight: bold;
}

.size32 {
  font-size: 32px;
  font-weight: normal;
}

.size18 {
  font-size: 18px !important;
}

.text18 {
  font-size: 18px !important;
  font-weight: normal;
}

.text18bold {
  font-size: 18px;
  font-weight: bold;
}

.text16s {
  font-size: 16px;
}

.text14s {
  font-size: 14px;
}

.text14 {
  font-size: 14px;
  font-weight: normal;
  color: var(--dark-grey);
}

.text12s {
  font-size: 12px;
}

.normalText {
  font-weight: normal;
}

.text14bold {
  font-size: 14px;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.colorStand {
  color: var(--dark-grey) !important;
}

.colorLightGray {
  color: var(--warm-grey) !important;
}

.colorLightBlue{
  color: var(--lightish-blue) !important;
}

.colorAnsarada {
  color: var(--ansarada-green) !important;
}

.colorAnsaradaDark {
  color: var(--ansarada-dark) !important;
}

.colorAnsaradaLight {
  color: var(--ansarada-light) !important;
}

.colorRed {
  color: var(--red) !important;
}

.colorWhite {
  color: var(--white) !important;
}

.colorOrange {
  color: #fe4912e8 !important;
}

.colorGreen {
  color: var(--purple) !important;
}

.colorSoft {
  color: var(--soft-grey);
}

.colorGreen2 {
  color: #1b9f2a;
}

.colorBlack {
  color: var(--black);
}

.colorLG {
  color: var(--loader-grey);
}

.colourYellow {
  color: yellow;
}

.shadeWhite {
  background-color: white;
}

.shade {
  background-color: var(--light-grey);
}

.shadeRed {
  background-color: rgba(248, 206, 206, 0.5);
}

.shadeYellow {
  background-color: rgba(255, 255, 209, 0.5);
}

.shadeGreen {
  background-color: rgba(215, 250, 188, 0.5);
}

.shadeOGreen {
  background-color: rgba(174, 230, 131, 0.5);
}

.bBotGrey {
  border-bottom: solid 1px var(--light-grey);
}

.error {
  font-size: 14px;
  font-weight: bold;
  /* font-style: italic; */
  color: var(--red);
}

.error-msg {
  font-size: 14px;
  font-weight: bold;
  /* font-style: italic; */
  color: var(--red);
}

.btn {
  height: 40px;
  border-radius: 4px;
  border: solid 1px var(--warm-grey);
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--warm-grey);
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
}

/* Buttons */
.btn-bg {
  min-height: 40px;
  border-radius: 4px;
  background-color: var(--ansarada-green);
  border: solid 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: white;
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
}

.btn-bghover {
  opacity: 0.9;
}

.btn-bgactive {
  filter: brightness(85%);
}

.btn-disable {
  opacity: 0.3;
  cursor: initial !important;
}

button:focus{
  outline: none;
  box-shadow: 0 0 0 2px var(--ansarada-green);
}

.btn-login {
  width: auto !important;
  display: inline-block !important;
  border-radius: 4px !important;
  padding: 7px 15px !important;
  border: 1px solid transparent !important;
  transition: color 165ms ease-out, background 165ms ease-out, border-color 165ms ease-out, box-shadow 330ms cubic-bezier(0.18, 0.8, 0.44, 1);
  background: #1e8c0d;
  border-color: #1e8c0d;
  text-shadow: 0 1px 0 rgb(11 11 13 / 40%);
  text-transform: none !important;
  height: 42px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer !important;
  line-height: normal;
}

.btn-loginhover {
  opacity: 0.9;
}

.btn-loginactive {
  filter: brightness(85%);
}

.no-label-select fieldset legend {
  display: none;
}

.btn-arrow {
  position: relative;
  padding: 0px 16px;
  height: 40px; /* 42px */
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  background-color: transparent;
  color: var(--ansarada-black);
  border: 1px solid var(--ansarada-grey);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
  z-index:1;
  cursor: pointer !important;
}

.btn-arrow:before{
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  width: 29px;
  height: 28px;
  border-left: 1px solid var(--ansarada-grey);
  border-top: 1px solid var(--ansarada-grey);
  transform: rotate(-45deg) translate(-14px,-7px);
}

.btn-arrowhover {
  color: var(--ansarada-black);
  filter: brightness(125%);
}

.btn-arrowactive {
  color: var(--ansarada-black);
  filter: brightness(85%);
}

@media screen and (max-width: 810px) {
  .header{
    background-color: white;
  }

  .btn-arrow {
    position: relative;
    margin-left: 20px;
    padding: 0px 16px;
    height: 42px;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    background-color: transparent;
    color: var(--ansarada-black);
    border: 1px solid var(--ansarada-grey);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: none;
    z-index:1;
    cursor: pointer !important;
    line-height: normal;
  }
}

.btn-clear {
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px var(--ansarada-grey);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #2c2c2e;
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
}

.btn-clearhover {
  filter: brightness(50%);
}

.btn-clearactive {
  background-color: var(--ansarada-grey);
  color: var(--white);
}

.btn-gray {
  height: 40px;
  border-radius: 4px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
  border: solid 1px var(--warm-grey);
  color: var(--warm-grey);
}

.btn-grayactive {
  background-color: var(--warm-grey);
  color: var(--white);
}

.btn-Back {
  padding: 0;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--ansarada-green);
  cursor: pointer !important;
  line-height: normal;
}

.btn-Back:focus {outline:0;}

.btn-add {
  width: 77px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px var(--warm-grey);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
  cursor: pointer !important;
  line-height: normal;
}

.btn-org {
  border-radius: 4px;
  border: solid 1px #b30000;
  background-color: var(--red);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
  cursor: pointer !important;
  line-height: normal;
}

.btn-orghover {
  opacity: 0.9;
}

.btn-orgactive {
  filter: brightness(85%);
}

.btn-high {
  height: 40px;
  border-radius: 4px;
  border: solid 1px #004df6;
  background-color: #004df6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--white);
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
}

.btn-highhover {
  filter: brightness(120%);
}

.btn-highactive {
  filter: brightness(50%);
}

.btn-add-label {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: var(--warm-grey);
}

.btn-lockerror {
  opacity: 0.5;
  border: solid 1px var(--red);
  color: var(--red);
}

.btn-tile {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  border: solid 1px var(--ansarada-green);
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  color: var(--ansarada-green);
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
}

.btn-tilehover {
  filter: brightness(50%);
}

.btn-tileactive {
  background-color: var(--ansarada-green);
  color: var(--white);
}

.btn-tang {
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px var(--tangerine);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--tangerine);
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
}

.btn-tanghover {
  filter: brightness(50%);
}

.btn-tangactive {
  background-color: var(--tangerine);
  color: var(--white);
  border-color: var(--red);
}

.btn-red {
  min-height: 40px;
  border-radius: 4px;
  border-color: #9a1212;
  background-color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--white);
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
}

.btn-redhover {
  filter: brightness(50%);
}

.btn-redactive {
  background-color: var(--white);
  color: var(--red);
}

.btn-none {
  border: none;
  min-height: 40px;
  color: var(--ansarada-green);
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 13px; */
  background-color: transparent;
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
  font-weight: bold;
}

.btn-nonehover {
  opacity: 0.9;
}

.btn-none:focus {
  outline:0 !important;
  box-shadow: none;
}

.btn-noneactive {
  filter: brightness(85%);
}

.btn-svg {
  border: none;
  min-height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 13px; */
  background-color: transparent;
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
  font-weight: bold;
}

.btn-svghover {
  opacity: 0.9;
}

.btn-svg:focus {
  outline:0 !important;
  box-shadow: none;
}

.btn-white {
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px white;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--ansarada-black);
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;
  cursor: pointer !important;
  line-height: normal;
}

.btn-whiterhover {
  filter: brightness(50%);
}

.btn-whiteactive {
  background-color: var(--ansarada-grey);
  color: var(--white);
}

.tipButton {
  position: relative;
  display: inline-block;
}

.tipButton > span {
  visibility: hidden;
  text-align: center;
  padding: 5px;
  background-color: white;
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: normal;

  position: absolute;
  z-index: 40;
  margin-left: 50%;
  white-space: nowrap;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tipButton:hover > span {
  visibility: visible;
}

.editDelete {
  margin-top: 15px;
  color: #f12314;
}

.nowrap {
  white-space: nowrap;
}

.link {
  cursor: pointer;
}

.unlink {
  cursor: default !important;
}

.htmlLink {
  color: var(--lightish-blue);
  cursor: pointer;
}

.limitText {
  overflow: hidden;
}

.textWrap {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.maxWidth {
  width: 100%;
}

.maxHeight {
  height: 100%;
}

.width80 {
  width: 80%;
}

.width50 {
  width: 50%;
}

/* Logo */
.boardlogo{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.boardlogoSmall{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: top !important;
}

.boardlogoNav{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: top !important;
}

.boardlogoImage{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.boardeditlogo{
  width: 60px;
  height: 60px;
  /*border-radius: 50%;*/
  position: relative;
}

.boardeditlogo:focus {outline:0;}

.tilelogoImage{
  width: 112px;
  height: 147px;
  box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.1);
  object-fit: contain;
}

.fboardtitle {
  padding-left: 10px;
  font-size: 18px;
  color: var(--dark-grey);
}

.LogoImage {
  border: solid 1px var(--ansarada-green);
}

.LogoFont {
  background-color: var(--white);
  font-size: 9px;
  font-weight: bold;
  color: var(--ansarada-green);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.charFont {
  background-color: var(--light-grey);
  font-size: 18px;
  font-weight: bold;
  color: var(--warm-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid var(--medium-grey);
}

.NoLogo:after {
  width: 30px;
}

.LoadingLogo:after {
  width: 20px;
}

.AddLogo:after {
  width: 30px;
  content: 'ADD LOGO';
}

.AddPhoto:after {
  width: 30px;
  content: 'ADD PHOTO';
}

/* Panels */
.NoCol {
  display:inline;
}

.boardpanel{
  display: flex;
  flex-direction: row;
}

.spacebetween{
  justify-content: space-between;
}

.flexend{
  justify-content: flex-end;
}

.flexstart{
  justify-content: flex-start;
}

.centerFlex{
  display: flex;
  justify-content: center;
}

.centerVFlex{
  display: flex;
  align-items: center;
}

.endVFlex{
  display: flex;
  align-items: flex-end;
}

.centerpanel {
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.gCenter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.lText {
  text-align: left;
}

.cText {
  text-align: center;
}

.errorPopup {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mfadeOut {
  animation: fadeOut ease 5s;
  -webkit-animation: fadeOut ease 5s;
  -moz-animation: fadeOut ease 5s;
  -o-animation: fadeOut ease 5s;
  -ms-animation: fadeOut ease 5s;
}


@keyframes fadeOut{
  0% { opacity:1;}
  75% { opacity:1;}
  100% { opacity:0;}
}

@-moz-keyframes fadeOut {
  0% { opacity:1; }
  75% { opacity:1;}
  100% { opacity:0; }
}

@-webkit-keyframes fadeOut {
  0% { opacity:1; }
  75% { opacity:1;}
  100% { opacity:0; }
}

@-o-keyframes fadeOut {
  0% { opacity:1; }
  75% { opacity:1;}
  100% { opacity:0; }
}

@-ms-keyframes fadeOut {
  0% { opacity:1; }
  75% { opacity:1;}
  100% { opacity:0; }
}

.middlePage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
  -moz-animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
  -o-animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
  animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
}

.middleColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fboardpanel {
  margin: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleBoards {
  margin: 60px 50px;
  display: flex;
}

.contact-container {
  margin: 50px 80px;
}

@media screen and (max-width: 810px) {
  .list {
    padding-top: 40px;
    padding-Left: 10px;
    padding-right: 10px;
  }

  .fboardpanel {
    margin: 40px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .titleBoards {
    margin: 40px 10px;
    display: flex;
  }
}

.padList {
  padding-Left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 810px) {
  .list {
    padding-top: 40px;
    padding-Left: 50px;
    padding-right: 50px;
  }

  .padList {
    padding-Left: 50px;
    padding-right: 50px;
  }
}

.centericon{
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.centericonMini{
  margin-top: 10px;
  margin-bottom: -34px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 810px) {
  .buttonrows {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media screen and (min-width: 810px) {
  .buttonrows {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 50px;
    padding-left: 50px;
  }
}
/* Alerts */
.aBoxTop-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 60;
  background: rgba(51, 51, 51, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif, arial;
  overflow-y: auto;
}

.aBoxTop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  background: rgba(51, 51, 51, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif, arial;
  overflow-y: auto;
}

.clear-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 70;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif, arial;
  overflow-y: auto;
}

.aBoxTop-cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  background: rgba(51, 51, 51, 0.99);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif, arial;
}

@-webkit-keyframes aBoxTop-overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes aBoxTop-overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes aBoxTop-overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes aBoxTop-overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.aPopup-large {
  height: 98vh;
  width: 98vw;
  min-height: 600px;
  overflow-y: auto;
  border-radius: 4px;
  background-color: var(--white);
}

@media screen and (min-width: 700px) {
  .aPopup-large {
    height: 98vh;
    width: 98vw;
    min-width: 600px;
    min-height: 600px;
    overflow-y: auto;
    border-radius: 4px;
    background-color: var(--white);
  }
}

.aPopup-box {
  max-height: 830px;
  max-width: 600px;
  width: 100%;
  border-radius: 4px;
  background-color: var(--white);
}

.aPopup-small {
  max-height: 800px;
  overflow-y: auto;
  width: 600px;
  border-radius: 4px;
  background-color: var(--white);
}

.aPopup-Header{
  margin:40px 50px 40px 50px;
}

.aPopup-HeaderNB{
  margin:40px 50px 0px 50px;
}

@media screen and (max-width: 500px) {
  .aPopup-Header{
    margin:40px 10px 40px 10px;
  }

  .aPopup-HeaderNB{
    margin:40px 10px 0px 10px;
  }

  .aPopup-content {
    padding: 30px 10px 30px 10px !important;
  }
}

.aPopup-content {
  background-color: var(--very-light-grey);
  max-height: 540px;
  border-radius: 4px;
  font-size: 14px;
  padding: 30px 50px 30px 50px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.aPopup-noPadding {
  background-color: var(--very-light-grey);
  height: 540px;
  border-radius: 4px;
  font-size: 14px;
  padding: 30px 0px 30px 0px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.aPopup-panel {
  background-color: var(--very-light-grey);
  border-radius: 4px;
  height: 100%;
  font-size: 14px;
  padding: 30px 50px 30px 50px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.aPopup-panelNB {
  background-color: var(--very-light-grey);
  border-radius: 4px;
  height: 85vh;
  font-size: 14px;
  padding: 30px 50px 30px 50px;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (max-width: 810px) {
  .aPopup-panelNB {
    background-color: var(--very-light-grey);
    border-radius: 4px;
    height: 85vh;
    font-size: 14px;
    padding: 30px 5px 30px 5px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .aPopup-panel {
    background-color: var(--very-light-grey);
    border-radius: 4px;
    height: 100%;
    font-size: 14px;
    padding: 30px 20px 30px 20px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.aPopup-option {
  margin: 20px;
}

.aPopup-device {
  width: 1200px;
  height: 800px;
  border-radius: 4px;
  background-color: var(--white);
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.uploadbox {
  position: fixed;
  width: 600px;
  height: 50px;
  bottom: 10px;
  left:50%;
  margin-left: -300px;
  background-color: white;
  border: solid 1px var(--light-grey);
  padding: 20px 20px 12px 20px;
}

.uploadText {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  color: var(--dark-grey);
}

.uploadFileName {
  color: var(--warm-grey);
  margin-left: 9px;
  height: 40px;
}

.testpro {
  background: transparent;
}

.progressBar{
  height: 2px;
}

.alert-popup {
  position: fixed;
  width: 300px;
  height: 50px;
  bottom: 10px;
  right:10px;
  background-color: white;
  border-radius: 4px;
  border: solid 1px var(--light-grey);
  padding: 20px 20px 12px 20px;
  z-index: 90;
}

.alert-exit {
  position: relative;
}

.alert-danger {
  border: solid 1px #f44336;
}

.alert-success {

}

.editPopup {
  position: fixed;
  z-index: 90;
  width: 145px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--white);
  border: solid 1px var(--light-grey);
  font-size: 14px;
  font-weight: normal;
  text-align: left;
}

.editPopup2 {
  position: absolute !important;
}

.editbox {
  margin: 30px 30px 30px 30px;
}

.boardPopup {
  position: fixed;
  z-index: 90;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--white);
  border: solid 1px var(--light-grey);
  font-size: 14px;
  font-weight: normal;
  text-align: left;
}

.boardPopup2 {
  position: absolute;
  z-index: 90;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--white);
  border: solid 1px var(--light-grey);
  font-size: 14px;
  font-weight: normal;
  text-align: left;
}

@media screen and (max-width: 810px) {
  .boardPopup2 {
    position: absolute;
    z-index: 90;
    right: 10px;
    margin: 0 !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    background-color: var(--white);
    border: solid 1px var(--light-grey);
    font-size: 14px;
    font-weight: normal;
    text-align: left;
  }
}

.binderPopup {
  position: absolute;
  margin-top: 1px;
  z-index: 90;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  background-color: var(--white);
  border: solid 1px var(--light-grey);
  font-size: 14px;
  font-weight: normal;
  text-align: left;
}

.boardbox {
  margin: 10px 10px 10px 10px;
}

.fListOpt {
  width: 30px;
  text-align: right;
}

.standardTextInput {
  height: 24px;
  width: 200px;
  border-radius: 4px;
  border: solid 1px var(--medium-grey);
}

.standardOsInput {
  font-size: 14px;
  height: 24px;
  width: 195px;
  border-radius: 4px;
  border: solid 1px var(--medium-grey);
  padding-left: 5px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.NoUserPanel{
  margin-top: 200px;
  font-size: 24px;
  font-weight: bold;
  color: var(--dark-grey);
  display: flex;
  flex-direction: column;
}

.boarditem{
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 var(--medium-grey);
}

.rowEdit {
  border-bottom: dashed 1px var(--medium-grey);
}

.rowSolid {
  border-bottom: solid 1px var(--medium-grey);
}

.textInput {
  font-size: 32px;
  width: 100%;
  font-weight: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-grey);
  background-color: transparent;
  border: 0px solid;
}

.TextInputNormal {
  width:100%;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: normal;
  color: var(--dark-grey);
  background-color: transparent;
  border: 0px solid;
  /*margin: 16px 0px 10px;*/
  height: 46px;
  max-height: 100px;
}

/* Tooltip container */
.toolp {
  position: relative;
  display: inline-block;
}

.toolp-top {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.toolp-bottom {
  top: 125%;
  left: 50%;
  margin-left: -60px;
}

.toolp-bottomright {
  top: 125%;
  left: -400%;
}

.toolp .toolptext {
  visibility: hidden;
  min-width: 120px;
  background-color: var(--warm-grey);
  color: var(--very-light-grey);
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.toolp-delay {
  transition-delay: 1.0s !important;
}

.toolp .toolptextSmall {
  visibility: hidden;
  width: 80px;
  height: 20px;
  font-size: 14px;
  background-color: var(--warm-grey);
  color: #ffffff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  left: -50px;
  top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolp:hover .toolptextSmall {
  visibility: visible;
}

.toolp:hover .toolptext {
  visibility: visible;
}

.toolp-top {
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
}

.toolp-right {
    bottom: 100%;
    left: 100%;
    margin-left: -60px;
}

.toolp-middle {
    bottom: 0%;
    left: 0%;
    margin-left: 0px;
}

.resetPass {
  font-size: 14px;
  color: var(--ansarada-green);
  cursor: pointer;
}

.search_icon{
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
}

.iconHide {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.userNameSel {
  width: 100%;
}

.device-info {
  position: absolute;
  min-width: 230px;
  max-width: 300px;
	background: var(--white);
	border: 2px solid var(--light-grey);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  padding: 20px;
  color: var(--warm-grey);
  line-height: 1.43;
  z-index: 50;
}

.device-info:after, .device-info:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.device-infoL:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: var(--white);
	border-width: 5px;
	margin-left: -130px;
}
.device-infoL:before {
	border-color: rgba(242, 242, 242, 0);
	border-bottom-color: var(--light-grey);
	border-width: 8px;
	margin-left: -133px;
}

.device-infoR:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: var(--white);
	border-width: 5px;
	margin-left: 130px;
}
.device-infoR:before {
	border-color: rgba(242, 242, 242, 0);
	border-bottom-color: var(--light-grey);
	border-width: 8px;
	margin-left: 133px;
}

.infoIcon{
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.notepanel{
  margin-top: 19px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
}

.IconDisable{
  opacity: 0.5;
}

.TextDisable{
  opacity: 0.9;
}

.TextDelete{
  color: var(--red);
}

.rightOption {
  font-size: 12px;
  text-align: right;
  margin-bottom: 10px;
}

.h100 {
  height: 100%;
}

.bottom30 {
  margin-bottom: 30px;
}

.bottom20 {
  margin-bottom: 20px;
}

.bottom10 {
  margin-bottom: 10px;
}

.setting-sub {
  padding-left: 50px;
  padding-bottom: 10px;
}

tab { padding-left: 6px; }

.sortby {
  float: right;
  /*width: 220px;*/
  font-size: 14px;
  margin-bottom: -30px;
  z-index: 49;
}

.sortbyW {
  width: 220px;
}

.sortby .Select-input {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}

.sortby .Select--single>.Select-control .Select-value, .Select-placeholder {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 30px;
  padding-left: 10px;
  margin-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sortby .Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

select,
select option {
  color: var(--dark-grey);
  font-style: normal;
}

select:invalid,
select option[value=""] {
  color: var(--warm-grey);
}

.dropDown {
  font-size: 18px;
}

.dropDown, .Select-input {
  vertical-align: middle;
}

.dropDown .Select--single>.Select-control .Select-value, .Select-placeholder {
  bottom: 0;
  color: #aaa;
  line-height: 30px;
  margin-top: 5px;
  padding-left: 10px;
  margin-left: -5px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropDown .Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.contextMenu {
  background: var(--white);
  padding: 20px;
  box-shadow: inset 0 -1px 0 0 var(--medium-grey);
  font-size: 14px;
  font-family: sans-serif, arial;
  color: var(--dark-grey);
  z-index: 50;
}

.hide {
  display: none;
}

.countryDrop{
  width: 410px;
}

.infoProgress {
  font-weight: normal;
  margin-left: 50px;
}

.helperAvatar {
  position: fixed;
  left: 20px;
  bottom: 20px;
  border-radius: 50%;
  border: 1px solid var(--lightish-blue);
}

.helperImage {
  width: 36px;
  height: 50px;
  object-fit: contain;
  padding: 5px;
}

.boardtitle {
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-grey);
  vertical-align: middle;
}

.borderError {
   border: red 2px solid;
}

.loadingIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loadingIcon label {
  font-style: italic;
  color: var(--dark-grey);
  font-size: 14px;
}

.fadeInText {
  -webkit-animation: loadingfadein 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: loadingfadein 1s; /* Firefox < 16 */
      -ms-animation: loadingfadein 1s; /* Internet Explorer */
       -o-animation: loadingfadein 1s; /* Opera < 12.1 */
          animation: loadingfadein 1s;
}

@keyframes loadingfadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes loadingfadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes loadingfadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes loadingfadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes loadingfadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.loadingIcon div {
  margin: 0px auto 0 auto;
}

.react-pdf__Document {
  flex: 1;
  overflow: auto;
  background-color: rgba(64, 64, 64, 1);
}

.pdfViewer {
  overflow: auto;
  background-color: rgba(64, 64, 64, 1);
}

.pdfPanel {
  overflow: auto;
}

.pdfNaked {
  overflow-y: hidden;
  overflow-x: hidden;
  min-height: 1014px;
  margin-right:10px;
}

.pdfNaked:hover {
  overflow-y: scroll;
  margin-right:0;
}

.pdfNakeHead {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 35px;
  background-color: var(--light-grey);
}

.pdfGrab {
  cursor: grab;
	cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
	cursor:    -moz-grab; /* Firefox 1.5-26 */
	cursor:         grab; /* W3C standards syntax, should come least */
}

.pdf-scroll-container {
  /*overflow: hidden;*/
}

.pdf-scroll-container--dragging {
  pointer-events: none;
  cursor: grabbing; /* fallback: no `url()` support or images disabled */
	cursor: -webkit-grabbing; /* Chrome 1-21, Safari 4+ */
	cursor:    -moz-grabbing; /* Firefox 1.5-26 */
	cursor:         grabbing; /* W3C standards syntax, should come least */
}

.pdf-dragging {
  cursor: grabbing !important;
	cursor: -webkit-grabbing !important;
	cursor:    -moz-grabbing !important;
	cursor:         grabbing !important;
}

.pdfvertical {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pdfhorizontal {
  display: flex;
  flex-direction: row;
}

.pdfwrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pdfGap {
  margin: 20px 0px;
}

.pdfPage {
}

.pdfHeader {
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.pdfHeader label {
  color: var(--dark-grey);
  align-items: center;
  display: flex;
}

div.pdfContent {
/*  min-height: 1014px;*/
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.pdfContentHead {
  background-color: rgba(0, 0, 0, 0.1);
  height: 0px;
  width: 200px;
}

.pdfContentScroll {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdfContentExp {
  flex-direction: row;
  flex-wrap: wrap;
}

.pdfContentSel {
  border: 3px solid var(--lightish-blue);
}

.pdfblank {
  border: 1px dashed white;
  min-width: 119px;
  min-height: 168px;
}

.pdfZoomIcon {
  color: var(--warm-grey);
  width: 16px;
  height: 16px;
  margin-top: 3px;
  margin-bottom: -3px;
}

.pdfZoomSel {
  border: none;
  background-color: var(--light-grey);
  font-size: 18px;
  width: 98%;
}

.pdfMenu {
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: var(--white);
  color: var(--warm-grey);
  line-height: 1.33;
}

.pdfMenu div {
  margin-left: 5px;
  margin-right: 5px;
}

.pdfMenu svg {
  margin: 6px 10px -6px 0px;
  width: 24px;
  height: 24px;
}

.pdfMenu span div {
  height: 2px;
  margin-left: 5px;
  width: 210px;
  border-bottom: 1px solid var(--medium-grey);
}

.pdfMenuSel {
  background-color: var(--light-grey);
}

.pdfExit {
  float: right;
  margin: 5px 20px;
}

.pdfIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}

.pdfIcon:hover {
  background-color: var(--warm-grey);
}

.pdf-canvas {
  position: absolute;
}

.canvas-container {
  position: absolute !important;
}

.pdfBut {
  display: flex;
}

.pdfdot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid var(--dark);
}

.pdfdotSel {
  height: 27px;
  width: 27px;
  border-radius: 50%;
  border: 1px solid white;
}

.pdfdotRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.drawToolbarButtonContainer {
  width: 250px;
}

.drawHangerRight {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.drawHangerRight:after, .drawHangerRight:before {
  bottom: 100%;
  border: solid rgba(0, 0, 0, 0);
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.drawHangerRight:after {
  border-bottom-color: rgba(82, 82, 82, 0.99);
  border-width: 8px;
  margin-left: 180px;
}
.drawHangerRight:before {
  border-bottom-color: rgba(0, 0, 0, 0.5);
  border-width: 9px;
  margin-left: 180px;
}

/*.pdfOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-grey);
  border-left: 1px solid var(--light-grey);
  margin-left: 20px;
  margin-right: 20px;
}

.pdfOption input[type=number]::-webkit-inner-spin-button,
.pdfOption input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}*/

.pdfFullScreen {
  width: 100%;
  height: 100%;
  background-color: var(--light-grey);
  display: flex;
  flex-direction: column;
}

.pdfEmbedded{
  background-color: var(--light-grey);
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  overflow-y: auto;
}

.pdfVerifyIcon {
  /*width: 50px;*/
  height: 30px;
  margin-left: 20px;
  cursor: pointer;
}

.pdfVerifyLabel {
  align-items: center;
  display: flex;
  margin-right: 10px;
  font-size: 14px;
}

.pdfBox {
  width: 80vW;
  max-width: 1000px;
  height: 90vH;
  max-height: 1000px;
  background-color: var(--light-grey);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1000px) {
  .pdfBox {
    width: 100vW;
    max-width: 1000px;
    height: 100vH;
    max-height: 1000px;
    background-color: var(--light-grey);
    border-radius: 0px;
    display: flex;
    flex-direction: column;
  }

  div.pdfContent {
    position: absolute;
    z-index: 51;
    left: 0;
    overflow: auto;
    background-color: rgba(0,0,0,0.7);
    bottom: 50px;
    top: 50px;
    display: flex;
    flex-direction: column;
  }
}

.pdfBoxHead {
  padding: 0px 10px;
  min-height: 50px;
  height: 50px;
  border-bottom: 1px solid var(--soft-grey);
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: var(--dark-grey);
}

.pdfBoxFoot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  height: 60px;
  border-top: 1px solid var(--soft-grey);
  padding: 0 10px;
}

.flag {
  box-sizing: border-box;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 20px 32px -8px;
  width: 50%;
  border-radius: 3px;
  padding: 16px;
  transition: background-color 200ms ease 0s;
  margin: 20px;
}

.fgblue {
  background-color: var(--lightish-blue);
  color: rgb(255, 255, 255);
}

.fgwhite {
  background-color: var(--white);
  color: var(--dark-grey);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 0px 32px 8px !important;
}

.flag label {
  font-size: 14px;
}

.icongap {
  cursor: pointer;
  margin-left: 10px;
}

.menu5 {
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.optdropdown {
  position: absolute;
  width: 100%;
  left: 0;
  top: 170px;
  z-index: 10;
  overflow-y: hidden;
  transition: all .5s ease-in-out;
  height: 0;
}

.sortIconM {
  width: 20px;
  float: right;
  margin-bottom: -20px;
}

.form-white {
  font-size: 18px;
  height: 40px;
  font-weight: normal;
  color: white;
  background-color: white! important;
  border: none;
  border-bottom: solid 1px white;
  width: 270px;
  text-align: center;
}

.popupFeatIcon span {
  visibility: hidden;
}

.popupFeatIcon:hover > span {
  visibility: visible;
}

.popupFeat {
  position: absolute;
  will-change: transform;
  top: 10px;
  left: 60px;
  transform: translate3d(0px, 41px, 0px);
  box-shadow: 0 24px 48px 10px rgba(35,51,64,.35)!important;
  border-radius: 5px;
  z-index: 10;
  background-color: white;
  padding: 10px;
}

.popupFeatarrow {
  position: absolute;
  top: -6px;
  left: 155px;
  margin: 0 0 0 -6px;
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  border-radius: 4px 0 0 0;
  background: #fff;
  box-shadow: -2px -3px 4px rgba(50,50,93,.05);
}

.loading-box {
  width: 320px;
  height: 120px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--white);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--ansarada-green);
}

.loading-per {
  width: 260px;
  height: 18px;
  margin-bottom: 20px;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--loader-grey);
}

.loader h4 {
  margin-top: 20px;
}

.loader-small {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: var(--loader-grey);
}

.loader-small label {
  margin-left: 5px;
  margin-bottom: 0px;
}

.loaderMini{
  width: 100%;
  display: flex;
  justify-content: center;
}

a:hover {
  text-decoration: none;
}

*, ::after, ::before {
  box-sizing: none; /*content-box;*/
}

.backdrop-loading-overlay-container {
  position: fixed;
  background: #00000099;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 100;
}
.backdrop-loading-overlay-container span {
  font-size: 24px;
}

.css-juf2xh-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: white;
  border-color: #1e8c1a !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #1e8c1a !important;
  cursor: default;
  display: webkit-box;
  display: webkit-flex;
  display: ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
  border-radius: 0.9rem !important;
  background-color: #1e8c1a !important;
  color: #fff;
}

.css-juf2xh-control:hover {
  border-color: #1e8c1a !important;
  box-shadow: 0 0 0 1px #1e8c1a !important;
}

.css-1vp9nu5-control {
  align-items: center;
  background-color: white;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #1e8c1a !important;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1vp9nu5-control:hover {
  border-color: #1C8715 !important;
  border-color: #1C8715 !important;
  box-shadow: 0 0 0 1px #1C8715 !important;
}

#maintenace {
  position: absolute;
  left: 25vw;
  right: 25vw;
  top: 0;
  margin: 0px;
  width: 50vw;
  font-size: 16px;
}

.apploader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.apploader div {
  display: flex;
  flex-direction: column;
  background-color: var(--ansarada-green);
  border-radius: 20px;
  padding: 10px;
  color: white;
}

.apploader label {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 185px;
  font-size: 16px;
  height: 190px;
}

.navtopapp {
  background-color: var(--white);
  margin: 0 !important;
  padding-right: 10px;
  height: 50px;
  border-bottom: 1px solid var(--ansarada-grey);
}

.navrow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.nextCalLoc {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nextCalLoc img {
  width: 24px;
  margin-left: -3px;
  margin-right: 3px;
}

.nextCalLoc label {
  margin: 0px;
}

.nextCalLoc .nextNoLoc {
  font-style: italic;
  color: var(--medium-grey);
}

@media screen and (max-width: 810px) {
  .hide-on-mobile {
    display: none;
  }
}

@media screen and (min-width: 811px) {
  .hide-on-desktop {
    display: none;
  }
}

.adminuser-select-button {
  flex: 1;
  padding: 50px 20px;
  cursor: pointer;
  border: 1px solid var(--ansarada-green);
  border-radius: 5px;
  text-align: center;
}

.adminuser-select-button-inner-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.adminuser-select-button-director-view:hover {
  border-width: 2px;
  background-color: #f0f0f0;
}

.adminuser-select-button-admin-view:hover {
  background: green;
}

.adminuser-select-button-admin-view {
  background-color: var(--ansarada-green);
  color: white;
}

.adminuser-select-button-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 20px;
}

@media screen and (max-width: 550px) {
  .adminuser-select-button-wrapper {
    flex-direction: column !important;
  }

  .adminuser-select-button {
    padding: 20px 5px !important;
  }
}