.icon-toolip {
  background: var(--warm-grey) !important;
  color: var(--very-light-grey) !important;
  box-shadow: none !important;
}

.bindernewitem {
  color: var(--lightish-blue);
}

.bBinderSec {
  min-height: 200px;
}

.bNoBinders {
  min-height: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bNoBinders label {
  font-size: 18px;
  font-weight: normal;
  color: #bebebe;
  margin-bottom: 20px;
}

.bNewBinderModal {
  width: 100%;
  max-width: 800px;
  min-height: 700px;
  border-radius: 4px;
  background-color: white;
}

.bNewBinder {
  width: 100%;
  max-width: 600px;
  min-height: 500px;
  border-radius: 4px;
  background-color: white;
}

.bNewBinderAuto {
  width: 600px;
  border-radius: 4px;
  background-color: white;
}

.binder-modal-align {
  align-items: center;
}

.bNewBinder-panel{
  margin: 40px 50px 0px 50px;
}

.bNewBinder-head{
  margin: 20px 20px 0px 50px;
}

.bNewBinder-selection{
  padding: 10px 50px 30px 50px;
  overflow: hidden;
}

.bNewBinder-Title {
  border-bottom: solid 1px var(--light-grey);
  padding-bottom: 20px;
}

.bNewBinder-Exit {
  margin-top: -20px;
  margin-right: -30px;
  color: var(--warm-grey);
}

.bNewBinder-Back {
  margin-top: -18px;
  margin-left: -30px;
  color: var(--warm-grey);
}

.bNewBinder-BRow {
  height: 120px;
}

.bNewBinder-Button{
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: normal;
  font-family: sans-serif, arial;
  color: var(--dark-grey);
  cursor: pointer;
}

.bNewBinder-Button:visited {
  color: var(--dark-grey);
}

.bNewBinder-copy {
  border-top: solid 1px var(--light-grey);
  background-color: var(--very-light-grey);
  height: 256px;
  padding: 0 50px 0px 50px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 810px) {
  .bNewBinderModal-copy-grey {
    /* border-top: solid 1px var(--light-grey); */
    background-color: var(--very-light-grey);
    height: 473px;
    padding: 10px 10px 0px 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .bNewBinderModal-copy {
    height: 473px;
    padding: 0px 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media screen and (min-width: 811px) {
  .bNewBinderModal-copy-grey {
    /* border-top: solid 1px var(--light-grey); */
    background-color: var(--very-light-grey);
    height: 473px;
    padding: 10px 50px 0px 50px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .bNewBinderModal-copy {
    height: 473px;
    padding: 0px 50px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.bNewBinder-section {
  border-top: solid 1px var(--light-grey);
  background-color: var(--very-light-grey);
  height: 50vh;
  max-height: 500px;
  padding: 0 50px 0px 50px;
  overflow-y: auto;
  overflow-x: hidden;
}

.bNewBinder-bpanel {
  background-color: var(--very-light-grey);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 374px;
}

.bNewBinder-fpanel {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 374px;
}

.bNewBinder-autopanel {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bNewBinder-template{
  display: flex;
  flex-direction: column;
}

.bNewBinder-temprow{
  height: 50px;
  color: var(--ansarada-green);
  font-size: 14px;
  border-bottom: solid 1px var(--light-grey);
  display: flex;
  align-items: center;
}

.binderedit label{
  font-size: 14px;
  font-weight: bold;
  color: var(--warm-grey);
}

.binderDate{
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: normal;
  color: var(--dark-grey);
  margin-top: 20px;
  margin-bottom: 6px;
}

.binderNoEdit{
  font-size: 18px;
  font-style: italic;
  color: var(--warm-grey);
  margin-top: 20px;
  margin-bottom: 6px;
}

.bindertextInput {
  font-size: 18px;
  letter-spacing: normal;
  text-align: left;
  color: var(--dark-grey);
  background-color: transparent;
  border: 0px solid;
  margin-top: 20px;
  margin-bottom: 6px;
}

.bindertextInputNew {
  font-style: italic;
  font-weight: normal;
  color: var(--warm-grey);
}

.binderdetailnew{
  padding-bottom: 50px;
  border-bottom: solid 1px var(--medium-grey);
}

.binderdetaildrop{
  border-bottom: solid 1px var(--medium-grey);
  /*margin-top: 16px;
  padding-bottom: 16px;*/
}

.binderitemlisthead{
  box-shadow: inset 0 -1px 0 0 var(--warm-grey);
  font-weight: bold;
  font-size: 14px;
  color: var(--warm-grey);
  height: 30px;
}

.templateRow{
  border-bottom: solid 1px var(--medium-grey);
  padding-top: 16px;
  padding-bottom: 16px;
}

.binderdetailitem{
  /* padding: 25px 4px !important; */
  min-height: 80px;
  font-size: 14px;
  color: var(--warm-grey);
  box-shadow: inset 0 -1px 0 0 var(--medium-grey);
  align-items: center;
  border-left: solid 5px transparent;
}

.binderdetailitem:hover {
  border-left: solid 5px var(--ansarada-green) !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.binderdetailitem.binderdetailmultidoc {
  height: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  padding: 5px 4px !important;
  box-shadow: none;
}

.binderdetailmultidoc-add-file {
  height: 60px !important;
  min-height: 60px !important;
  box-shadow: inset 0 -1px 0 0 var(--medium-grey) !important;
}

.binderdetailmultidoc-multidoc-main {
  box-shadow: none !important;
}

.binderdetailitem.binderdetailmultidoc .binderitemmulti-inner {
  padding-left: 10px;
  align-items: center;
}

.binderdetailitem.binderdetailmultidoc .binderitemmulti-inner * {
  align-items: center;
  align-self: center;
}

.binderAddOpt {
  font-size: 14px;
  color: var(--dark-grey);
  border: none;
  background: none;
  text-align: left;
  margin-top: 5px;
  cursor: pointer;
}

.boptLeft {
  border-right: solid 1px var(--medium-grey);
  padding-left: 20px;
}

.boptRight{
  padding-left: 20px;
}

.attendeeslist {
  border-right: solid 1px var(--medium-grey);
}

.inviteeslist{
  padding-left: 20px;
  margin-bottom: 50px;
}

.inviteeTextInput {
  font-size: 14px;
  width:100%;
  height: 40px;
  border-radius: 4px;
  background-color: var(--very-light-grey);
  border: solid 1px var(--medium-grey);
  padding-left: 20px;
  padding-right: 20px;
}

.inviteeEditnew {
  font-size: 14px;
  font-weight: normal;
  color: var(--warm-grey);
}

.userattendeename{
  font-size: 14px;
  font-weight: normal;
  margin-left: 10px;
}

.groupname{
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}

.binderitem-bar{
  margin-top: -2px;
  /*margin-right: 6px;*/
}

.binderitem-bar:focus {
  outline:none;
}

.binderitem-drag{
  border: 0px solid;
  /*overflow: hidden;*/
}

.binderitem-file-drag-hover {
  border: 1px solid green;
}

.binderitem-Input {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: var(--dark-grey);
  background-color: transparent;
  border: 0px solid;
  width: 100%;
}

.binderitem-InputNew {
  font-style: italic;
  font-weight: normal;
  color: #b500ff;
  display: inline-block;
}

.binderitem-InputError {
  font-style: italic;
  font-weight: normal;
  color: #b500ff;
  display: inline-block;
}

.binderitem-votepanel{
  width: 600px;
  max-height: 80vh;
  min-height: 600px;
  border-radius: 4px;
  background-color: white;
  padding: 40px 50px 40px 50px;
  color: var(--dark-grey);
  overflow-y: auto;
}

.binderitem-respanel{
  width: 600px;
  max-height: 80vh;
  border-radius: 4px;
  background-color: white;
  padding: 40px 50px 40px 50px;
  color: var(--dark-grey);
  overflow-y: auto;
}

.binderitem-VInput {
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  color: var(--dark-grey);
  background-color: transparent;
  border: 0px solid;
  border-bottom: 1px dashed var(--medium-grey);
  font-family: sans-serif;
}

.binderitem-VInputNew {
  font-style: italic;
  font-weight: normal;
  color: var(--warm-grey);
}

.binderitem-VAInput {
  font-size: 14px;
  width:100%;
  border-radius: 4px;
  background-color: transparent;
  border: solid 1px var(--medium-grey);
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}

.binderitem-Search {
  font-size: 14px;
  width:100%;
  border-radius: 4px;
  height: 40px;
  background-color: transparent;
  border: solid 1px var(--medium-grey);
  padding-left: 20px;
  padding-right: 20px;
}

.binderitem-SearchEmpty {
  padding-left: 25px;
  color: var(--warm-grey);
}

.iconbin-container{
  display:inline-block;
  padding: 20px;
  margin-right: 15px;
  /*height:250px;*/
  margin-bottom: 5px;
  border-radius: 10px;
}

.bfilterRow {
  display: flex;
  flex-direction: row;
}

.bSpaceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bSelOpt {
  margin-top: -30px;
  margin-bottom: 40px;
}

.mobileBOptions {
  display: none;
}

@media screen and (max-width: 1050px) {
  #itemDivCont, .smallScreen {
    display: none !important;
  }

  .mobileBOptions {
    display: block !important;
  }
}

@media screen and (max-width: 410px) {
  .iconbin-container{
    padding: 20px;
    border-radius: 5px;
    margin-right: 0px;
    /*height:250px;*/
    margin-bottom: 20px;
    display:inline-block;
  }

  .bNewBinder-panel{
    margin: 40px 10px 0px 10px;
  }

  .bNewBinder-Exit {
    margin-top: -20px;
    margin-right: 0px;
    color: var(--warm-grey);
  }

  .bNewBinder-Back {
    margin-top: -18px;
    margin-left: 0px;
    color: var(--warm-grey);
  }

  .logExit{
    margin: -25px 0px !important;
  }

  .bfilterRow {
    display: flex;
    flex-direction: column;
  }

  .bSpaceRow {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .boptLeft {
    border-right: none;
    padding-left: 0px;
  }

  .boptRight{
    padding-left: 0px;
  }

  .attendeeslist {
    border-right: none;
  }

  .inviteeslist{
    padding-left: 0px;
  }

  #itemDivCont, .smallScreen {
    display: none !important;
  }

  .bSelOpt {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .secBind {
    contain: strict;
  }

  .mobileBOptions {
    display: block !important;
  }
}

.iconbin-container:hover {
  background-color: var(--light-grey);
}

.iconbin-panel {
  display: flex;
  flex-direction: column;
  position: relative;
/*  padding-top: 5px;*/
}

.binder-excluded-label {
  background: #2bbd25;
  position: absolute;
  bottom: 17px;
  font-size: 11px;
  color: white;
  text-align: center;
  width: 101%;
  height: 20px;
  display: flex;
  line-height: 11px;
  justify-content: center;
  align-items: center;
}

.iconbin-title {
  font-size: 12px;
  width:112px;
  text-align: center;
}

.iconbin-Archivetitle {
  font-size: 20px;
  width: 500px;
  text-align: left;
  margin-left: 20px;
}

.iconbin-message{
  position: absolute;
  bottom: 25px;
  width: 76px;
  height: 22px;
  background-color: var(--red);
  text-align: center;
  color: var(--white);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 10px 10px 0;
}

.iconbin-message label {
  margin-bottom: 0;
}

.archiveLabel {
  font-size: 20px;
  margin-left: 80px;
  border-bottom: 1px solid var(--medium-grey);
}

.bDashTitle{
  padding-bottom: 16px;
}

.bDashDetailRow:not(:first-of-type){
  padding-Top: 16px;
  padding-Bottom: 20px;
}

.bDashOption{
  height: 60px;
  font-size: 14px;
  color: var(--lightish-blue);
  display: inline-flex;
  align-items: center;
}

.bDashStatus{
  font-size: 14px;
  color: var(--warm-grey);
  margin-bottom: 20px;
  margin-top: 30px;
  width: 100%;
  flex-wrap: wrap;
  height: 10px;
}

.bDashStatus > * {
  margin-right: 20px;
}


.bDashTable table {
  font-size: 14px;
  font-weight: bold;
  color: var(--warm-grey);
  margin-bottom: 20px;
  border: none;
  border-collapse: collapse;
}

.bDashTable th td {
  border: none;
}

.bDashTable td {
  border: none;
  text-align: center !important;
}

.bDashTable tr {
  box-shadow: inset 0 -1px 0 0 var(--medium-grey);
}

.bDashTable th {
  box-shadow: inset 0 -1px 0 0 var(--warm-grey);
}

.bDashTable-ArrowL{
  width: 20px;
}

.bDashTable-ArrowR{
  width: 20px;
  text-align: right;
}

.bDashTable .thd {
  vertical-align: bottom;
  padding-bottom: 18px;
}

.bDashTable .thdA {
  vertical-align: bottom;
}

th.tableHead {
  min-width: 40px;
  text-align: center !important;
}

th.rotate {
  /* Something you can count on */
  height: 140px;
  white-space: nowrap;
  min-width: 40px;
}

th.rotate > div {
  transform:
    /* Magic Numbers */
    translate(25px, 51px)
    /* 45 is really 360 - 45 */
    rotate(315deg);
  width: 30px;
}

.dashOffset {
  bottom: 20px;
  left: -60px;
}

.shareCount {
  position: absolute;
  margin-top: -25px;
  margin-left: 10px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background-color: #F44336;
  border-radius: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bWBounce {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.selectAll{
  float: right;
  margin-top: 15px;
}

.itemProgbar{
  /*margin: -10px 10px 10px 10px;*/
  margin: 0px 10px 0px 10px; /*Votes*/
  width: 20px;
  display: flex;
  justify-items: center;
}

.voteDate{
  width: 250px;
  border: none;
  background-color: transparent;
  border-bottom: 1px dashed var(--medium-grey);
  font-size: 18px;
  font-weight: normal;
  color: var(--dark-grey);
}

.voteDate-VInputNew {
  width: 250px;
  border: none;
  background-color: transparent;
  border-bottom: 1px dashed var(--medium-grey);
  font-size: 18px;
  font-style: italic;
  font-weight: normal;
  color: var(--warm-grey);
}

.voteAnsScroll {
  overflow: auto;
  max-height: 200px;
}

.pDashIcon {
  padding: 2px;
}

.bDashIcon {
  width: 10px;
}

.bDashSpace {
  margin-right: 4px;
}

.leftdir {
  float:left;
  color: var(--warm-grey);
  margin: 50px 0px 0px -40px;
}

.rightdir {
  float:right;
  color: var(--warm-grey);
  margin: 50px -50px;
}

.CircularProgressbar-text{
  display: none;
}

.radioOption {
  border-bottom: none;
  display: flex;
  justify-content: space-between;
}

.droparea {
  display: flex;
  align-items: center;
  justify-content: center;
  /*max-width: 800px;
  width: 60vw;
  */
  width: 100%;
  height: 230px;
  border: 2px dashed;
  color: var(--warm-grey);
  border-color: var(--warm-grey);
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.droparea:focus {outline:0;}

.droparea label {
  font-weight: bold;
}

.droparea span {
  font-weight: normal;
}

.droparea .icon {
  width: 58px;
  height: 73px;
}

.dropTextSelect {
  font-weight: normal;
  letter-spacing: normal;
  color: var(--dark-grey);
  background-color: transparent;
  border: 0px solid;
}

.dropTextSelect:focus {outline:0;}

.dropareaSide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: var(--warm-grey);
  border-color: var(--medium-grey);
  border: 2px dashed;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  font-size: 16px;
}

.dropareaSide:focus {outline:0;}

.dropareaSide .icon {
  width: 58px;
  height: 73px;
}

.dropareaSide div {
  font-weight: bold;
}

.binder-Search {
  font-size: 14px;
  border-radius: 4px;
  height: 36px;
  border: solid 1px var(--medium-grey);
  z-index: 99;
  padding-left: 5px;
}

.binder-SearchEmpty {
  color: var(--warm-grey);
}

.sortby .search_icon {
  position: absolute;
  margin-top: 13px;
  margin-left: -20px;
}

.yearlabels {
  font-size: 16px;
  text-decoration: underline;
  margin: 0 0 20px 20px;
}

.repInfo{
  margin-top: -3px;
}

.replacehint {
  position: absolute;
  min-width: 230px;
  margin-top: 3px;
  background: var(--very-light-grey);
  border: 2px solid var(--light-grey);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  padding: 20px;
  color: var(--warm-grey);
  z-index: 99;
}

.DropTextDisplay{
  width: 100%;
  max-width: 382px;
  overflow: hidden;
}

.error_row{
  box-shadow: inset 0 -1px 0 0 var(--medium-grey);
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
}

.butExit {
  margin: 20px 20px -20px -20px;
}

.selectionCount{
  right: -10px;
  top: -10px;
  color: var(--warm-grey);
  background: var(--light-grey);
  border-radius: 50%;
  border: 1px solid var(--dark-grey);
  height: 30px;
  width: 30px;
  line-height: 30px;
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
}

.BinderRow{
  /* needed for SelectionCount */
  position: relative;

}

.menuIcon{
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
}

.logPanel{
  margin-bottom: 10px;
}

.logDate{
  margin-top: 5px;
  font-weight: bold;
}

.logDatePick{
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: normal;
  color: var(--dark-grey);
}

.logName{
  margin-top: 5px;
  margin-left: 10px;
  font-weight: bold;
  /* font-style: italic; */
}

.logText{
  margin-left: 20px;
}

.logSelection{
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 150px;
}

.logExit{
  float: right;
  margin: -25px -25px;
}

.copyenable{
  width: 24px;
  margin: 5px 20px 5px 0px;
}

.copydisable{
  width: 32px;
  margin: 5px 17px 5px -5px;
}

/* The container */
.checkbox {
  display: block;
  position: relative;
/*  padding-left: 25px;
  margin-bottom: 12px;
  margin-top: -12px;*/
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 25px;
  height: 65px;
  margin-left: -5px;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-mark {
  position: absolute;
  margin-top: -8px;
  top: 50%;
  left: 3px;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: solid 1px var(--medium-grey);
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkbox-mark {
  background-color: var(--ansarada-green);
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkbox-mark {
  background-color: var(--ansarada-green);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkbox-mark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkbox-mark:after {
  left: 5px;
  top: 1px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-sizing: revert;
}

.noCheckBox{
  padding-left: 25px;
}

.stickydiv{
  /* position: -webkit-sticky; */
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 20; */
  flex: 0;
  background-color: var(--very-light-grey);
}

.itemControl{
  padding-left: 50px;
  padding-right: 4px;
  padding-top: 10px;
  height: 100%;
  min-height: 200px;
  /*position: -webkit-sticky;*/
  position: sticky;
  top: 94px;
  width: 240px;
}

.itemControl-overflow{
  overflow: auto;
}

.itemControlAbs{
  position: fixed;
  /*padding-left: 20px;*/
  height: 100%;
  min-height: 200px;
  top: 86px;
  margin-top: 10px;
}

.addByDrag label{
  font-size: 12px;
  font-style: italic;
  color: var(--warm-grey);
}

.dragnewitem{
  height: 32px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px var(--ansarada-green);
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  color: var(--ansarada-green);
  text-decoration: none;
  cursor: pointer;
}

.dragnewText{
  display: flex;
  flex-direction: row;
  margin-top: -8px;
  align-items: center;
}

.dragnewIcon{
  width: 10px;
  height: 15px;
  margin-left: 5px;
  margin-right: 14px;
}

.itemoptions{
  margin: 15px 0px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  color: var(--ansarada-green);
}

.itemsperator{
  padding: 5px 0px;
}

.standDragCount{
  background-color: #333333;
  border-radius: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -3px;
  top: -8px;
  height: 20px;
  width: 20px;
}

.standDragCount label {
  color: white;
}

.selectFading{
    position:relative;
}
.selectFading:after {
    content:"";
    position:absolute;
    top:0px;
    left:0;
    height:80px;
    width:100%;
    background: linear-gradient(rgba(0,0,0,0), rgb(221, 230, 249));
}
.pulsating-circle {
  animation: pulse-dot infinite ease-in-out 2s 0.3s;
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

.menuOptionIcon{
  width: 15px;
  /*height: 10px;*/
  color: var(--ansarda-green);
  margin-right: 12px;
}

.menuOptionIc{
  width: 13px;
  color: var(--ansarda-green);
  margin-right: 12px;
}

.binderitemLeft {
  font-size: 14px;
  font-weight: 500;
  color: var(--loader-grey);
  justify-self: flex-end;
  align-self: center;
}

.errorStar {
  width: 25px;
  height: 25px;
  padding: 5px;
}

.undoBut {
  color: var(--dark-grey);
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-bottom: -32px;
}

.waitingReg {
  font-size: 12px;
  color: var(--warm-pink);
  font-style: italic;
}

.selectOption {
  font-size: 12px;
  color: var(--ansarada-green);
  margin-right: 20px;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.archiveBoard {
  align-items: center;
  font-weight: bold;
}

.itemRowAdmin {
  background-color: rgba(215, 250, 188, 0.5)
}

.AttRecpRow {
  margin-right: 10px;
  padding-right: 5px;
  padding-left: 5px;
}

.agendadetailitem{
  height: 50px;
  font-size: 14px;
  color: var(--warm-grey);
  box-shadow: inset 0 -1px 0 0 var(--medium-grey);
  align-items: center;
}

.wrapperClassName {
  border: solid 1px var(--warm-grey);
}

.toolbarClassName {
  background-color: var(--dark-grey);
}

.editorClassName {
  background-color: var(--white);
  height: 200px !important;
  margin: 0px 10px;
  font-family: Montserrat, sans-serif, arial;
}

.bNewBinder-bindrow{
  /* height: 100px; */
  color: var(--lightish-blue);
  font-size: 16px;
  /* border-bottom: solid 1px var(--light-grey); */
  /* line-height: 1.25; */
  /* display: flex; */
  /* align-items: center; */
}

.copyBindImg {
  margin: auto;
}

.copyBindOff {
  margin-top: -2px;
}

.vote-content {
  background-color: var(--very-light-grey);
  height: 300px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0px 50px 30px 50px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.vote-export {
  background-color: var(--very-light-grey);
  max-height: 330px;
  height: 80vh;
  border-radius: 4px;
  font-size: 14px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
}

.exportp {
  flex: 2;
  padding: 20px 50px;
}

.tabCol {
  border: solid 1px var(--warm-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  width: 100%;
  color: var(--warm-grey);
  cursor: pointer;
}

.tabColSel {
  background-color: var(--ansarada-green);
  border: solid 1px var(--ansarada-green);
  color: white;
  cursor: default;
}

.voteDisplay ul {
  /*width: 280px;*/
  margin-top: -4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  padding-left: 1px;
  font-size: 14px;
}

.voteDisplay li {
  min-height: 40px;
  box-shadow: inset 0 -1px 0 0 var(--medium-grey);
  display: inline;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.voteContent {
  display: flex;
  flex-direction: column;
  transition: max-height 0.2s ease-out;
}

.exportItem {
  display: flex;
  flex-direction: column;
}

.exportItem label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.exportItem span {
  font-size: 12px;
}

.resolutionItem label {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.resolutionItem span {
  font-size: 16px;
}

.zipIcon {
  width: 70px;
  margin-left: -5px;
  margin-top: 4px;
}

.binderHelp {
  width: 100%;
  max-width: 800px;
  padding: 15px;
}

.bCheckBox {
  position: absolute;
  min-width: 20px;
  min-height: 20px;
  /* z-index: 30; */
}

.sendNote {
  align-items: center;
  font-size: 16px;
  font-style: italic;
}

.binderStaButs {
  padding-bottom: 10px;
  overflow-x: auto;
  margin-bottom: 10px;
}

.binderMAction {
  float: right;
  /* padding: 7px; */
  border-radius: 4px;
  border: solid 1px var(--ansarada-green);
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: bold;
  color: var(--ansarada-green);
}

.binderMAction > div:last-child {
  padding: 0;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  background-color: var(--ansarada-green);
}

.binderMAction > div:first-child {
  padding: 7px;
}

.binderMDrop {
  position: absolute;
  width: 170px;
  right: 0;
  margin-top: 35px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 5px;
}

@media screen and (min-width: 410px) {
  .binderDate220{
    width: 220px;
  }
}

.prevSearch {
  padding: 10px 110px 10px 50px;
  background-color: var(--very-light-grey);
}

.preIcon {
  width: 40px;
  height: 40px;
}

.pdfPass {
  color: var(--dark-grey);
  border: 1px solid var(--lightish-blue);
  min-width: 135px;
}

@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 400;
  src: local('EB Garamond'), local('EB Garamond-Regular'),
       url('/lib/fonts/EBGaramond.woff2') format('woff2'),
       url('/lib/fonts/EBGaramond.woff') format('woff');
/*       url('/lib/fonts/EBGaramond.ttf') format('truetype');*/
}

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  src: local('Helvetica'), local('Helvetica-Regular'),
       url('/lib/fonts/Helvetica.woff2') format('woff2'),
       url('/lib/fonts/Helvetica.woff') format('woff');
       /*url('/lib/fonts/Helvetica.ttf') format('truetype');*/
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato'), local('Lato-Regular'),
       url('/lib/fonts/Lato.woff2') format('woff2'),
       url('/lib/fonts/Lato.woff') format('woff');
       /*url('/lib/fonts/Lato.ttf') format('truetype');*/
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: local('Merriweather'), local('Merriweather-Regular'),
       url('/lib/fonts/Merriweather.woff2') format('woff2'),
       url('/lib/fonts/Merriweather.woff') format('woff');
       /*url('/lib/fonts/Merriweather.ttf') format('truetype');*/
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat'), local('Montserrat-Regular'),
       url('/lib/fonts/Montserrat.woff2') format('woff2'),
       url('/lib/fonts/Montserrat.woff') format('woff');
       /*url('/lib/fonts/Montserrat.ttf') format('truetype');*/
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('Open Sans-Regular'),
       url('/lib/fonts/OpenSans.woff2') format('woff2'),
       url('/lib/fonts/OpenSans.woff') format('woff');
       /*url('/lib/fonts/OpenSans.ttf') format('truetype');*/
}

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display'), local('Playfair Display-Regular'),
       url('/lib/fonts/PlayfairDisplay.woff2') format('woff2'),
       url('/lib/fonts/PlayfairDisplay.woff') format('woff');
       /*url('/lib/fonts/PlayfairDisplay.ttf') format('truetype');*/
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'), local('Raleway-Regular'),
       url('/lib/fonts/Raleway.woff2') format('woff2'),
       url('/lib/fonts/Raleway.woff') format('woff');
       /*url('/lib/fonts/Raleway.ttf') format('truetype');*/
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url('/lib/fonts/Roboto.woff2') format('woff2'),
       url('/lib/fonts/Roboto.woff') format('woff');
       /*url('/lib/fonts/Roboto.ttf') format('truetype');*/
}

.bItem label {
  margin-bottom: 0px !important;
}

.disableBinder {
  font-size: 18px;
  font-weight: bold;
  color: var(--warm-grey);
}

.binderLock {
  color: #ebc815;
  width: 80px;
  height: 80px;
}

.templateLock {
  width: 25px;
  height: 25px;
  color: #ebc815;
}

.dashLock {
  width: 25px;
  height: 25px;
  color: #ebc815;
  margin-right: 10px;
}

.binderOpt {
  margin-right: 50px !important;
  width: 200px !important;
}

.filterSection {
  width: 100%;
  display: inline-flex;
}

.filterType {
  width: 50%;
}

.filterSort {
  width: 50%;
}

.filterSort__control {
  font-size: 15px !important;
}

.filterSort__menu {
  font-size: 15px !important;
}

.binder-document-upload {
  font-size: 14px;
  color: var(--loader-grey);
}

.binder-document-upload-bar {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  display: inline-block;
}

.binderFreeDel {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.binderFreeDel label {
  margin: 0;
}

.usageFreemium {
    display: flex;
    margin-bottom: 30px;
}

.usageFreemium img {
  max-width: 800px;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .usageFreemium {
    flex-direction: column;
  }

  .usageFreemium img {
    margin-top: 20px;
  }
}

.usageFreemium span {
    margin-right: 20px;
}


.usageFreemium label {
  margin: 40px 0px;
}

.binder-items-container .fFileList {
  padding: 0;
  flex: 1;
}

.binder-item-selection-drag-handle, .binder-item-selection-icons {
  flex: 1;
}

.binder-item-selection-drag-handle {
  flex-direction: row;
  display: flex;
  align-items: center;
  align-self: start;
}

.binder-items-popper-container {
  position: relative;
  max-width: 600px;
  min-width: 400px;
}

.binder-wizard-documents-uploading-text {
  flex: 1;
  justify-content: center;
  display: flex;
  border: 1px solid green;
  padding: 10px;
  border-radius: 8px;
  margin-right: 10px;
}

.binder-items-popper-container tr {
  padding: 10px 0;
}

.binder-items-popper-container td {
  padding: 5px;
  vertical-align: top;
}

.binder-items-popper-container tr td:first-of-type {
  text-align: center;
  color: #999999;
}

.binder-items-popper-container tr td:nth-of-type(2) {
  padding-left: 10px;
}

.binder-items-popper-container tr td:nth-of-type(3) {
  padding-left: 20px;
}

.binder-item-main-container {
  width: 90%;
  align-items: center;
  padding: 30px 0;
}
.binder-item-main-container-multidoc {
  padding-bottom: 0;
}

.binder-item-document-details.binder-item-document-container-drag-over {
  padding: 25px 0;
  transition: height 0.2s;
}
.binder-item-document-details {
  position: relative;
}
.binder-item-document-details-dragover-info {
  display: none;
  z-index: 999;
}
.binder-item-document-file-drag-over.binder-item-document-details-dragover-info {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}


.binder-items-document-type-icon {
  margin-right: 10px;
  color: var(--ansarada-green);
}

.view-as-user-type {
  color: var(--ansarada-black);
}

.view-as-user {
  padding: 10px 0;
  cursor: pointer;
}

.view-as-dialog-container {
  max-height: 80vh;
  overflow: auto;
}

.vote-item-modal-title {
  display: flex;
  justify-content: space-between;
}
.vote-item-modal-field {
  padding: 8px 0px;
}

.binder-board-page-tabs-container {
  flex-direction: row;
  flex-wrap: wrap;
}

.binder-board-header {
  background-color: white;
}

.bBinderPanel {
  overflow: hidden;
}

.binder-board-page-binders {
  overflow: auto;
  flex: 1;
}

.binder-board-page-sort-container {
  padding-left: 15px;
  display: flex;
}

.binder-edit-page-button-container {
  padding-right: 66px;
}

.sort-icon-asc {
  transform: rotateX(180deg);
}
.sort-icon-desc {
  transform: rotateX(0deg);
}

.binder-meeting-loc-input img {
  width: 25px;
  margin-right: 10px;
}

.binder-meeting-loc-input .meeting-loc-webex-img {
  transform: scale(3.2);
}

.binder-name-title {
  position: absolute;
  top: -18px;
  left: 0;
  font-size: 12px;
  color: var(--gray);
  white-space: nowrap;
}

.binder-dash-desktop-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
}


.dialogResVoteOptionHover:hover {
  cursor: pointer;
  background-color: #e5e5e5;
}

.dialogResVoteOptionHover:hover * {
  cursor: pointer;
}

@media screen and (max-width: 1020px) {
  .binder-board-page-sort-container {
    display: block;
    padding-top: 5px;
    align-self: end;
  }

  .binder-board-page-tabs-container {
    flex-direction: column !important;
  }
  .binder-board-page-tabs-container > div:not(.binder-board-page-sort-container) {
    width: 100%;
  }

  .binder-item-main-container {
    padding: 20px;
    padding-left: 0;
  }

  .binder-edit-page-button-container {
    padding-right: 80px !important;
  }
}

@media screen and (max-width: 810px) {
  .bBinderPanel {
    padding: 20px 5px 40px 5px;
    min-width: 320px;
  }

  .binderdetailitem {
    padding: 10px 4px !important;
  }

  .binder-dash-mobile-header {
    font-size: 22px !important;
  }
}

@media screen and (min-width: 810px) {
  .bBinderPanel {
    padding: 20px 50px 40px 50px;
    min-width: 320px;
  }
}

.binder-dash-mtp-limit-text {
  font-size: 12px;
  color: var(--warm-grey);
}
.binder-dash-mtp-description-text {
  font-size: 12px;
  padding-left: 32px;
  color: #6d6d6d;
}
.binder-dash-mtp-radiobutton-container {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 700px) {
  .binder-dash-mtp-limit-text {
    display: block;
    padding-left: 32px;
  }
  .binder-dash-mtp-radiobutton-container {
    display: block;
  }
}

.binder-dash-settings-description {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 8px;
  align-items: flex-start;
  padding-bottom: 20px;
  flex: 1 1;
  border-radius: 5px;
  font-weight: bold;
  color: #000000;
}

.binder-dash-user-settings {
  cursor: pointer;
  font-weight: 400;
  transition: all 0.3s;
}

.binder-dash-user-settings:hover {
  color: var(--ansarda-green);
}