.help-field {
  width: 100%;
}

.help-field button {
  float: right;
  margin: 20px;
}

.logContent{
  background-color: #f7f7f7;
  color: var(--ansarada-black);
  height: 100vh;
  font-size: 16px;
}

.logboxContent{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 95%;
}

.loginVersion{
  height: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  line-height: 1.43;
  color: var(--ansarada-black);
}

.loginVersion label {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.loginbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  border-radius: 4px;
  border: 1px solid #ebebed;
  padding: 24px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%), 0 4px 8px -4px rgb(0 0 0 / 10%);
}

@media screen and (min-width: 476px) {
  .loginbox {
    max-width: 600px;
  }
}

@media screen and (max-width: 475px) {
  .loginbox {
    max-width: 100vw;
  }
}

.loginbox h1 {
  color: var(--ansarada-black);
}

.login-lock-header {
  height: auto !important;
  font-size: 18px;
  position: relative;
  padding: 11px;
}

.Athena-logo {
  object-fit: contain;
  margin-bottom: 30px;
  margin-top: 20px;
  width: 110px;
}

.Asarada-logo {
  object-fit: contain;
  margin-bottom: 30px;
  height: 25px;
}

.forgotten {
  font-size: 14px;
  font-weight: normal;
  margin-top: 70px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.support {
  font-size: 14px;
  font-weight: normal;
  margin-top: 20px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.login-field {
  width: 100%;
}

.login-field label {
  float: left;
  margin-bottom: 8px !important;
}

.loginUserNameApp label {
  display: block !important;
}

.f-control {
  font-size: 16px;
  height: 46px;
  font-weight: normal;
  color: var(--ansarada-dark);
  background-color: transparent;
  border: 1px solid #babcbe!important;
  border-radius: 5px!important;
  width: 100% !important;
  padding: 0 8px!important;
  /*270px !important;*/
}

.f-control:focus {outline:0;}

.f-controlempty {
  /*font-style: italic;*/
}

.spin-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(52, 61, 72, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
  -moz-animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
  -o-animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
  animation: confirm-alert-fadeIn 0.2s 0.2s forwards;
}

.bpanel-Exit {
  margin-top: -10px;
  color: var(--warm-grey);
}

.auth-panel{
  margin: 25px 20px 20px 50px;
}

.authmarg {
  margin: 0 50px 20px 50px;
  text-align: left;
}

.authmarg-password {
  margin: 0 50px 20px 50px;
  text-align: left;
}

.mui-login-container {
  padding: 20px 50px;
}

.authmarg label {
  font-size: 14px;
  font-weight: normal;
  color: var(--dark-grey);
}

.authmarg span {
  font-size: 18px;
  font-weight: normal;
  color: var(--warm-grey);
}

.authmarg boldspan {
  font-size: 18px;
  font-weight: bold;
  color: var(--warm-grey);
}

.screenmarg {
  margin: 0 50px 20px 50px;
  text-align: left;
}

.screenmarg label {
  font-size: 18px;
  font-weight: normal;
  color: var(--warm-grey);
  margin-bottom: 10px;
}

.screenmargnm {
  margin: 0 50px 0px 50px;
  text-align: left;
}

.authcode{
  margin: 20px 0px 40px 0px;
}

.authcodeSmall{
  margin: 20px 0px 20px 0px;
}

.authcode input[type='text'], .authcode input[type='password'] {
  border: 1px solid var(--medium-grey);
  border-radius: 4px;
  height: 40px;
  width: 100% !important;
  color: var(--dark-grey);
  font-size: 24px;
  font-weight: bold;
  margin-left: -5px;
  padding-left: 5px;
}

input[type=text]:focus .ainput{
  outline: none;
  box-shadow: 0 0 0 2px var(--ansarada-green);
}

.authcode input::-webkit-outer-spin-button,
.authcode input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.forgot-msg{
  text-align: left;
  margin: 0 50px 20px 50px;
}

.qrCamera {
  color: var(--ansarada-green);
}

.login-reg {
  position: absolute;
  width: 400px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background: var(--light-grey);
  color: var(--warm-grey);
  line-height: 1;
  z-index: 99;
}

.centerAbs {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.login-reg:after, .login-reg:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.login-reg:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: var(--light-grey);
	border-width: 5px;
  radius: 2px;
	margin-left: 0px;
}
.login-reg:before {
	border-color: rgba(242, 242, 242, 0);
	border-top-color: var(--light-grey);
	border-width: 8px;
  radius: 2px;
	margin-left: -3px;
}

.refreshDis{
  margin-top: 3px;
  margin-bottom: -3px;
  margin-right: 5px;
}

.hintp{
  margin-top: 5px;
  margin-bottom: 0px;
}

.qrPopUp {
  position: fixed;
  margin-left: 250px;
  margin-top: -50px;
}

.qrPopUpExit {
  margin-bottom: -20px;
  margin-left: 210px;
}

.confirmInvMin {
  max-height: 300px;
  overflow-y: auto;
}

.confirmInvRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--medium-grey);
}

.resendIcon {
  display: flex;
  margin-top: 5px;
}

.resendIcon span {
  color: var(--ansarada-green);
  margin-left: 5px;
  cursor: pointer;
}

.codesent {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 10px;
}

.fgtext {
  display: flex;
  color: var(--warm-grey);
  font-size: 14px;
}

.fgBox {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
}

.fgpanel {
  padding-top: 28px;
  padding-bottom: 28px;
  background-color: var(--very-light-grey);
}

.fgBox span {
  font-size: 18px;
  color: var(--lightish-blue);
  margin-bottom: 5px;
}

.fgBox label {
  font-size: 14px;
}

.fgBox div {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin-left: 11px;
  margin-right: 11px;
}

.fgadmins {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.fgworddash {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fgworddash label {
  font-size: 18px;
}

.fgworddash span {
  font-size: 32px;
  color: var(--dark-grey);
  margin-top: 20px;
  margin-bottom: 20px;
}

.fgworddash div {
  display: flex;
    justify-content: center;
}

.fgpwd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 50px 30px 50px;
}

.fgpwd label {
  font-size: 14px;
  color: var(--warm-grey);
  margin-bottom: 5px;
}

.fgpwd input[type='text'], .fgpwd input[type='password'] {
  border: 1px solid var(--medium-grey);
  border-radius: 4px;
  height: 40px;
  width: 100%;
  color: var(--dark-grey);
  font-size: 18px;
  /*font-weight: bold;*/
  margin-left: -5px;
  padding-left: 5px;
  background-color: white;
}

.fgpwd input::-webkit-outer-spin-button,
.fgpwd input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.fgrec label {
  font-weight: bold;
}

.fgpassempty {
  color: var(--warm-grey)!important;
  /*font-style: italic;*/
  font-weight: normal !important;
}

.fgicon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.fgcam {
  width: 500px;
  height: 255px;
  border-radius: 4px;
  border: solid 1px var(--medium-grey);
      margin: auto;
}

.loginUserName {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 5px;
}

.loginUserName:hover {
  background-color: var(--light-grey);
  border: 1px solid var(--medium-grey);
  border-radius: 4px;
}

.loginUserName label {
  cursor: pointer;
  max-width: 80vw;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lpassword {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.lpassword svg {
  margin-left: -50px;
  margin-right: 10px;
  height: 40px;
  cursor: pointer;
}

.Auth0OverLay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
}

.Auth0Centre {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.auth0Frame {
  width: 600px;
  height: 800px;
  border: none;
}

.login-iframe-exit {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -450px;
  margin-left: 430px;
  animation: 5s fadeIn;
  animation-fill-mode: forwards;

  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.login-button-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.login-error-msg {
  text-align: center;
  color: #DB353D !important;
  font-size: 13px !important;
  line-height: 16px !important;
  border: 1px solid;
  border-radius: 4px;
  margin: 8px 0 16px;
  text-transform: none !important;
  font-weight: inherit !important;
  padding: 8px 24px !important;
  width: 95%;
}

.login-error-msg span {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.login-forgot {
  text-decoration: none !important;
  font-size: 13px;
  color: #1e8c0d !important;
  font-weight: bold;
  -webkit-font-smoothing: subpixel-antialiased;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.login-forgot label {
  display: block !important;
  cursor: pointer;
}

#privacy-note {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 13px;
  line-height: 16px !important;
  letter-spacing: 0.34px;
  color: #57575A !important;
  text-align: center;
  margin-bottom: 20px;
}

#privacy-note a {
  text-decoration: none !important;
  color: #1e8c0d !important;
  font-weight: bold;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

.Login-loader {
  background-color: #f7f7f7;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
}

.ansarada-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.signInput {
  margin: 32px 35px 8px;
}

.signUpError {
  font-size: 13px;
  line-height: 32px;
  letter-spacing: .34px;
  font-weight: 400;
  color: #db353d;
}

.signUpFooter {
  margin-top: 25px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -.4px;
  color: #84898a;
}

.cookieAccept {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #343d48;
}

.cookieText {
  color: #fff;
  margin: 8px;
  display: flex;
  align-items: center;
}

.cookieBut {
  margin-left: 15px;
}

.cookieLink {
  text-decoration: none;
  text-decoration-line: underline !important;
  color: #fff;
}

.signs1start {
  margin-bottom: 50px;
}

@media screen and (max-width: 550px) {
  .signs1start {
    padding: 0 15px;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 551px) {
  .signs1start {
    margin-left: 150px;
    margin-bottom: 50px;
  }
}

.signs1start h4 {
  margin-bottom: 16px;
  font-size: 25px;
  line-height: 28px;
  letter-spacing: -.2px;
  font-weight: 700;
}

.signUpPanel {
  /* width: 50vh; */
  max-width: 500px;
}

.signq {
  margin-top: 12px;
  margin-bottom: 12px;
}

.signs1start a {
  color: var(--ansarada-green);
}

.signs1but {
  margin-top: 40px;
  max-width: 500px;
  display: flex;
  justify-content: flex-end;
}

.signUpcombo {
  z-index: 10;
}

.admin-page-switch-to-director-link {
  font-size: 14px;
  color: var(--ansarada-green);
  font-weight: bold;
  padding: 20px 0 10px 0;
  text-align: center;
  cursor: pointer;
}